<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>成绩管理</el-breadcrumb-item>
      <el-breadcrumb-item>成绩状态</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="teacher"
          filterable
          placeholder="请选择老师"
          clearable
          @clear="queryScoreStatus"
        >
          <el-option
            v-for="item in teacherList"
            :key="item.id"
            :label="item.teacher"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="scoreList"
          multiple
          filterable
          placeholder="请选择录入状态"
          clearable
          @clear="queryScoreStatus"
          style="margin-left: 20px"
        >
          <el-option label="已提交" value="1"></el-option>
          <el-option label="未提交" value="0"></el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="margin-left: 20px"
          @click="queryScoreStatus"
          :disabled="this.teacher == '' && this.scoreList.length == 0"
          >搜索</el-button
        >
        <el-button
          type="primary"
          @click="queryScoreStatusExcelData"
          id="addbutton"
          icon="el-icon-download"
          >导出</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="scoreStatusList"
      class="studentTable"
      :row-class-name="tableRowClassName"
      :row-style="{ height: '65.2px' }"
      max-height="439px"
    >
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="tag" label="录入状态" width="140">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.score === 1">已提交</el-tag>
          <el-tag v-else-if="scope.row.score === 0" type="danger"
            >未提交</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="140">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="140">
      </el-table-column>
      <el-table-column align="center" prop="tag" label="类型" width="140">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.kind === 1">必修</el-tag>
          <el-tag v-else-if="scope.row.kind === 2" type="success">选修</el-tag>
          <el-tag v-else-if="scope.row.kind === 3" type="danger">重修</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="100">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="150"
      >
      </el-table-column>
      <el-table-column align="center" prop="usual" label="平时成绩" width="100">
        <template slot-scope="scope"> {{ scope.row.usual }}% </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="experiment"
        label="实验成绩"
        width="100"
      >
        <template slot-scope="scope"> {{ scope.row.experiment }}% </template>
      </el-table-column>
      <el-table-column align="center" prop="exam" label="期末成绩" width="100">
        <template slot-scope="scope"> {{ scope.row.exam }}% </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
export default {
  name: "Score",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      scoreStatusList: [],
      //表格绑定的数据
      scoreList: [],
      teacherList: [],
      teacher: "",
      excelData: [],
    };
  },
  created() {
    this.queryAllScoreStatus();
  },
  methods: {
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        课程号: "id",
        课程名: "course",
        课程类型: "kind",
        学分: "credit",
        任课教师: "teacher",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: `未提交成绩名单`,
        sheet: "课程名单",
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        if (item.kind === 1) item.kind = "必修";
        else if (item.kind === 2) item.kind = "选修";
        else if (item.kind === 3) item.kind = "重修";
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryScoreStatusExcelData() {
      this.$axios({
        method: "get",
        url: "/queryScoreStatusExcelData",
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.exportExcel();
      });
    },
    async queryAllScoreStatus() {
      this.$axios({
        method: "get",
        url: `/queryAllScoreStatus/${this.pagenum}/${this.pagesize}`,
      }).then((res) => {
        const { data } = res.data;
        this.scoreStatusList = data.scoreStatusList;
        this.total = data.count;
        this.teacherList = data.teacherList;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.student === "") this.queryAllScoreStatus();
      else this.queryScoreStatus();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.student === "") this.queryAllScoreStatus();
      else this.queryScoreStatus();
    },
    async queryScoreStatus() {
      this.$axios({
        method: "post",
        url: `/queryScoreStatus/${this.pagenum}/${this.pagesize}`,
        data: {
          teacher: this.teacher,
          scoreList: this.scoreList,
        },
      }).then((res) => {
        const { data } = res.data;
        this.scoreStatusList = data.scoreStatusList;
        this.total = data.count;
      });
    },
    tableRowClassName({ row }) {
      if (row.usual + row.experiment + row.exam != 100) {
        return "warning-row";
      }
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.el-table >>> .warning-row {
  color: red;
  /*  font-weight:bold;*/
}
</style>
