<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>调课申请</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="teacher"
          filterable
          placeholder="请选择老师"
          clearable
          @clear="queryAdjustCourse"
        >
          <el-option
            v-for="item in teacherList"
            :key="item.id"
            :label="item.teacher"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="result"
          filterable
          placeholder="请选择结果"
          clearable
          @clear="queryAdjustCourse"
          style="margin-left: 20px"
        >
          <el-option label="待审核" value="0"></el-option>
          <el-option label="同意" value="1"></el-option>
          <el-option label="拒绝" value="2"></el-option>
        </el-select>
        <el-button
          type="primary"
          @click="queryAdjustCourse"
          :disabled="this.teacher === '' && this.result === ''"
          style="margin-left: 20px"
          >搜索</el-button
        >
        <el-button
          type="success"
          @click="agreeAdjustCourse"
          :disabled="this.multipleSelection == ''"
          style="margin-left: 20px"
          >同意</el-button
        >
        <el-button
          type="danger"
          @click="refuseAdjustCourse"
          :disabled="this.multipleSelection == ''"
          style="margin-left: 20px"
          >拒绝</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="adjustCourseList"
      class="evaluationTable"
      ref="multipleTable"
      :row-style="{ height: '66px' }"
      max-height="444px"
      tooltip-effect="dark"
      :row-key="getRowKeys"
      @selection-change="handleSelectionChange"
      @row-click="handleSelection"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
        :reserve-selection="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="id" label="编号" width="80">
      </el-table-column>
      <el-table-column align="center" prop="tag" label="结果" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.result === 0">待审核</el-tag>
          <el-tag v-else-if="scope.row.result === 1" type="success"
            >同意</el-tag
          >
          <el-tag v-else-if="scope.row.result === 2" type="danger">拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程号" width="130">
      </el-table-column>
      <el-table-column align="center" prop="name" label="课程名" width="130">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="130"
      >
      </el-table-column>
      <el-table-column align="center" prop="from" label="原周次" width="110">
      </el-table-column>
      <el-table-column align="center" prop="to" label="现周次" width="110">
      </el-table-column>
      <el-table-column align="center" prop="week" label="现星期" width="110">
      </el-table-column>
      <el-table-column align="center" prop="lesson" label="现节次" width="110">
      </el-table-column>
      <el-table-column align="center" prop="reason" label="原因" width="150">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "AdjustCourse",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      adjustCourseList: [],
      teacherList: [],
      teacher: "",
      result: "",
      multipleSelection: [],
      idList: [],
    };
  },
  created() {
    this.queryAllAdjustCourse();
  },
  methods: {
    async agreeAdjustCourse() {
      console.log(this.result);
      this.multipleSelection.forEach((item) => {
        this.idList.push(item.id);
      });
      this.multipleSelection = [];
      this.$axios({
        method: "post",
        data: {
          idList: this.idList,
        },
        url: "/agreeAdjustCourse",
      }).then((res) => {
        const { msg } = res.data;
        this.idList = [];
        this.toggleSelection();
        this.successMessage(msg);
        if (this.teacher === "" && this.result === "")
          this.queryAllAdjustCourse();
        else this.queryAdjustCourse();
      });
    },
    async refuseAdjustCourse() {
      this.multipleSelection.forEach((item) => {
        this.idList.push(item.id);
      });
      this.multipleSelection = [];
      this.$axios({
        method: "post",
        data: {
          idList: this.idList,
        },
        url: "/refuseAdjustCourse",
      }).then((res) => {
        const { msg } = res.data;
        this.idList = [];
        this.toggleSelection();
        this.successMessage(msg);
        if (this.teacher === "" && this.result === "")
          this.queryAllAdjustCourse();
        else this.queryAdjustCourse();
      });
    },
    async queryAllAdjustCourse() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllAdjustCourse",
      }).then((res) => {
        const { data } = res.data;
        this.adjustCourseList = data.adjustCourseList;
        this.teacherList = data.teacherList;
        this.total = data.count;
      });
    },
    async queryAdjustCourse() {
      this.pagenum = 1;
      this.$axios({
        method: "get",
        url: "/queryAdjustCourse",
        params: {
          teacher: this.teacher,
          result: this.result,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.adjustCourseList = data.adjustCourseList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.teacher === "" && this.result === "")
        this.queryAllAdjustCourse();
      else this.queryAdjustCourse();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.teacher === "" && this.result === "")
        this.queryAllAdjustCourse();
      else this.queryAdjustCourse();
    },
    getRowKeys(row) {
      return row.id;
    },
    handleSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.evaluationTable {
  width: 100%;
  height: 444px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
.el-tag {
  width: 60px;
}
</style>
