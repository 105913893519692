<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>课程列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入课程名"
          @clear="loadCourseList"
          clearable
          v-model="course"
          class="inputSearch"
          @keydown.enter.native="queryStudentCourse"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryStudentCourse"
          ></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-table
      :data="studentCourseList"
      class="studentTable"
      :row-style="{ height: '65.2px' }"
      max-height="439px"
    >
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="150">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="100">
      </el-table-column>
      <el-table-column align="center" prop="tag" label="类型" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.kind === 1">必修</el-tag>
          <el-tag v-else-if="scope.row.kind === 2" type="success">选修</el-tag>
          <el-tag v-else-if="scope.row.kind === 3" type="danger">重修</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="140"
      >
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="120">
      </el-table-column>
      <el-table-column align="center" prop="from" label="起(周)" width="100">
      </el-table-column>
      <el-table-column align="center" prop="to" label="止(周)" width="100">
      </el-table-column>
      <el-table-column align="center" prop="week" label="星期" width="80">
      </el-table-column>
      <el-table-column align="center" prop="start" label="上(节)" width="100">
      </el-table-column>
      <el-table-column align="center" prop="end" label="下(节)" width="100">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "StudentCourse",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      studentCourseList: [],
      course: "",
    };
  },
  created() {
    this.queryAllStudentCourse();
  },
  methods: {
    async queryAllStudentCourse() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllStudentCourse",
      }).then((res) => {
        const { data } = res.data;
        this.studentCourseList = data.studentCourseList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "") this.queryAllStudentCourse();
      else this.queryStudentCourse();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "") this.queryAllStudentCourse();
      else this.queryStudentCourse();
    },
    async queryStudentCourse() {
      if (this.course === "") return;
      this.$axios({
        method: "get",
        url: `/queryStudentCourse/${this.course}/${this.pagenum}/${this.pagesize}`,
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.successMessage(msg);
          this.studentCourseList = data.studentCourseList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadCourseList() {
      await this.queryAllStudentCourse();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
