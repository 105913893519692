<template>
  <el-card v-if="retakeStatus" class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>重修管理</el-breadcrumb-item>
      <el-breadcrumb-item>重修选课</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入课程名称"
          @clear="loadChoiceList"
          clearable
          v-model="course"
          class="inputSearch"
          @keydown.enter.native="queryStudentRetake"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryStudentRetake"
          ></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-table :data="studentRetakeList" class="studentTable" max-height="505">
      <el-table-column align="center" type="index" label="序号" width="60">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="80">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="100">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="60">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="80"
      >
      </el-table-column>
      <el-table-column align="center" prop="grade" label="上课班级" width="310">
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="100">
      </el-table-column>
      <el-table-column align="center" prop="from" label="起(周)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="to" label="止(周)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="week" label="星期" width="50">
      </el-table-column>
      <el-table-column align="center" prop="start" label="上(节)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="end" label="下(节)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="number" label="剩余" width="60">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.kind === 1"
            type="primary"
            key="1"
            :disabled="!scope.row.disabled"
            @click="addStudentRetake(scope.row)"
            >跟班</el-button
          >
          <el-button
            v-if="scope.row.kind === 3"
            type="primary"
            key="3"
            :disabled="!scope.row.disabled"
            @click="addStudentChoice(scope.row)"
            >报名</el-button
          >
          <el-button
            v-else-if="scope.row.kind === 4"
            type="danger"
            key="4"
            @click="deleteChoice(scope.row)"
            >退选</el-button
          >
          <el-button
            v-if="scope.row.kind === 2"
            key="full"
            disabled
            type="warning"
            >已满</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "StudentRetake",
  data() {
    return {
      //表格绑定的数据
      studentRetakeList: [],
      course: "",
      retakeStatus: false,
    };
  },
  created() {
    this.queryAllStudentRetakeCourse();
  },
  methods: {
    async queryAllStudentRetakeCourse() {
      this.$axios({
        method: "get",
        url: "/queryAllStudentRetakeCourse",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.studentRetakeList = data.studentRetakeList;
          this.total = data.count;
          this.retakeStatus = true;
        } else {
          this.retakeStatus = false;
          this.errorMessage(msg);
        }
      });
    },
    async queryStudentRetake() {
      if (this.course === "") return;
      this.$axios({
        method: "get",
        url: `/queryStudentRetake/${this.course}`,
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.studentRetakeList = data.studentRetakeList;
          this.successMessage(msg);
        } else if (code === 500) {
          this.errorMessage(msg);
        } else {
          this.retakeStatus = false;
          this.errorMessage(msg);
        }
      });
    },
    async loadChoiceList() {
      this.queryAllStudentRetakeCourse();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
    async addStudentChoice(e) {
      this.$axios({
        method: "get",
        params: {
          id: e.id,
        },
        url: "/addStudentChoice",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.queryAllStudentRetakeCourse();
        } else if (code === 300) {
          this.errorMessage(msg);
          this.queryAllStudentRetakeCourse();
        } else if (code === 400) {
          this.errorMessage(msg);
        } else {
          this.retakeStatus = false;
          this.errorMessage(msg);
        }
      });
    },
    async addStudentRetake(e) {
      this.$axios({
        method: "get",
        params: {
          id: e.id,
          classroom: e.classroom,
        },
        url: "/addStudentRetake",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.queryAllStudentRetakeCourse();
        } else if (code === 300) {
          this.errorMessage(msg);
          this.queryAllStudentRetakeCourse();
        } else if (code === 400) {
          this.errorMessage(msg);
          this.queryAllStudentRetakeCourse();
        } else {
          this.retakeStatus = false;
          this.errorMessage(msg);
        }
      });
    },
    async deleteChoice(e) {
      if (e.grade === "组班") this.deleteStudentChoice(e);
      else this.deleteStudentRetake(e);
    },
    async deleteStudentRetake(e) {
      this.$axios({
        method: "get",
        params: {
          id: e.id,
        },
        url: "/deleteStudentRetake",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.queryAllStudentRetakeCourse();
        } else {
          this.retakeStatus = false;
          this.errorMessage(msg);
        }
      });
    },
    async deleteStudentChoice(e) {
      this.$axios({
        method: "get",
        params: {
          id: e.id,
        },
        url: "/deleteStudentChoice",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.queryAllStudentRetakeCourse();
        } else {
          this.retakeStatus = false;
          this.errorMessage(msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  margin-top: 15px;
  overflow: auto;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
</style>
