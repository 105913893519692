<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>课程列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入课程号"
          @clear="loadCourseList"
          clearable
          v-model="id"
          class="inputSearch"
          @keydown.enter.native="queryTeacherCourse"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryTeacherCourse"
          ></el-button>
        </el-input>
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="queryTeacherCourseId"
          id="addbutton"
          >学生名单</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="teacherCourseList"
      class="studentTable"
      :row-style="{ height: '65.2px' }"
      max-height="439px"
    >
      <el-table-column align="center" type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="80">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="130">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="50">
      </el-table-column>
      <el-table-column align="center" prop="tag" label="类型" width="70">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.kind === 1">必修</el-tag>
          <el-tag v-else-if="scope.row.kind === 2" type="success">选修</el-tag>
          <el-tag v-else-if="scope.row.kind === 3" type="danger">重修</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="grade" label="上课班级" width="300">
      </el-table-column>
      <el-table-column align="center" prop="number" label="人数" width="60">
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="100">
      </el-table-column>
      <el-table-column align="center" prop="from" label="起(周)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="to" label="止(周)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="week" label="星期" width="50">
      </el-table-column>
      <el-table-column align="center" prop="start" label="上(节)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="end" label="下(节)" width="60">
      </el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="danger"
            @click="
              form.course = scope.row.id;
              from = scope.row.from;
              to = scope.row.to;
              adjustCourseDialog = true;
            "
            >调课</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      title="导出学生名单"
      :visible.sync="dialogExcelFormVisible"
      style="text-align: center; width: 70%; margin-left: 17%"
    >
      <el-select
        style="width: 100%"
        v-model="courseId"
        filterable
        placeholder="请选择课程号"
      >
        <el-option
          v-for="item in courseList"
          :key="item.id"
          :label="item.id"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExcelFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="queryTeacherCourseExcelData"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="申请调课"
      :visible.sync="adjustCourseDialog"
      width="600px"
      center
    >
      <el-form
        :model="form"
        ref="form"
        label-position="left"
        label-width="60px"
      >
        <el-form-item label="原周次" prop="fromList">
          <el-row>
            <el-col>
              <el-checkbox-group v-model="form.fromList">
                <el-checkbox-button
                  v-for="city in number"
                  :label="city"
                  :key="city"
                  style="width: 48px"
                  :disabled="city < from || city > to"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col>
            <el-col>
              <el-checkbox-group v-model="form.fromList">
                <el-checkbox-button
                  v-for="city in number1"
                  :label="city"
                  :key="city"
                  style="width: 48px"
                  :disabled="city < from || city > to"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col></el-row
          >
        </el-form-item>
        <el-form-item label="现周次" prop="toList">
          <el-row>
            <el-col>
              <el-checkbox-group v-model="form.toList">
                <el-checkbox-button
                  v-for="city in number"
                  :label="city"
                  :key="city"
                  style="width: 48px"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col>
            <el-col>
              <el-checkbox-group v-model="form.toList">
                <el-checkbox-button
                  v-for="city in number1"
                  :label="city"
                  :key="city"
                  style="width: 48px"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col></el-row
          >
        </el-form-item>
        <el-form-item label="现星期" prop="weekList">
          <el-checkbox-group
            v-model="form.weekList"
            style="margin-left: -500px !important"
          >
            <el-checkbox-button
              v-for="city in week"
              :label="city"
              :key="city"
              style="width: 48px"
              >{{ city }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="现节次" prop="lessonList">
          <el-checkbox-group v-model="form.lessonList">
            <el-checkbox-button
              v-for="city in number"
              :label="city"
              :key="city"
              style="width: 48px"
              >{{ city }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="原因" prop="reason">
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: -5%; margin-left: 400px"
      >
        <el-button @click="adjustCourseDialog = false">取 消</el-button>
        <el-button type="primary" @click="addAdjustCourse">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
export default {
  name: "TeacherCourse",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      status: false,
      teacherCourseList: [],
      excelData: [],
      courseList: [],
      dialogExcelFormVisible: false,
      id: "",
      courseId: "",
      courseName: "",
      adjustCourseDialog: false,
      from: "",
      to: "",
      form: {
        course: "",
        fromList: [],
        toList: [],
        weekList: [],
        lessonList: [],
        reason: "",
      },
      number: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      number1: ["11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
      week: ["1", "2", "3", "4", "5", "6", "7"],
    };
  },
  created() {
    this.queryAllTeacherCourse();
  },
  methods: {
    addAdjustCourse() {
      this.$axios({
        method: "post",
        url: "/addAdjustCourse",
        data: {
          course: this.form.course,
          fromList: this.form.fromList,
          toList: this.form.toList,
          weekList: this.form.weekList,
          lessonList: this.form.lessonList,
          reason: this.form.reason,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.adjustCourseDialog = false;
          this.$refs["form"].resetFields();
        } else {
          this.errorMessage(msg);
        }
      });
    },
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        班级: "grade",
        学号: "id",
        姓名: "student",
        性别: "sex",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: `${this.courseName}(${this.courseId})学生名单`,
        sheet: "学生信息",
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryTeacherCourseId() {
      this.$axios({
        method: "get",
        url: "/queryTeacherCourseId",
      }).then((res) => {
        const { data } = res.data;
        this.dialogExcelFormVisible = true;
        this.courseList = data.courseList;
      });
    },
    async queryTeacherCourseExcelData() {
      this.$axios({
        method: "get",
        url: `/queryTeacherCourseExcelData/${this.courseId}`,
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.courseName = data.courseName;
        this.dialogExcelFormVisible = false;
        this.exportExcel();
        this.courseId = "";
        this.courseName = "";
      });
    },
    async queryAllTeacherCourse() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllTeacherCourse",
      }).then((res) => {
        const { data } = res.data;
        this.teacherCourseList = data.teacherCourseList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.id === "") this.queryAllTeacherCourse();
      else this.queryTeacherCourse();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.id === "") this.queryAllTeacherCourse();
      else this.queryTeacherCourse();
    },
    async queryTeacherCourse() {
      if (this.id === "") return;
      this.$axios({
        method: "get",
        url: "/queryTeacherCourse",
        params: {
          id: this.id,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.successMessage(msg);
          this.teacherCourseList = data.teacherCourseList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadCourseList() {
      await this.queryAllTeacherCourse();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
