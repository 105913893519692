<template>
  <el-container class="container">
    <el-header class="header">
      <el-row>
        <el-col :span="4">
          <div class="grid-content bg-purple name">
            <h3 v-if="getAccess() == 1">管理员</h3>
            <h3 v-else-if="getAccess() == 2">学生</h3>
            <h3 v-else-if="getAccess() == 3">教师</h3>
          </div>
        </el-col>
        <el-col :span="16" class="middle">
          <div class="grid-content bg-purple-light">
            <h3>教务系统</h3>
          </div>
        </el-col>
        <el-col :span="4">
          <el-dropdown
            class="el-dropdown-link"
            trigger="hover"
            style="margin-left: 30%"
            @command="handleCommand"
          >
            <h1>
              <div class="icon">
                <i
                  class="el-icon-user-solid"
                  style="font-size: 20px; color: white"
                ></i>
                <span style="color: white; margin-left: 5px; font-size: 20px">{{
                  getName()
                }}</span>
              </div>
            </h1>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">
                <i class="el-icon-circle-close"></i>退出登录</el-dropdown-item
              >
              <el-dropdown-item command="2">
                <i class="el-icon-refresh"></i>修改密码</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </el-header>
    <el-container class="c">
      <el-aside width="15vw" class="aside">
        <el-menu
          :unique-opened="true"
          :router="true"
          :default-active="$route.path"
        >
          <el-submenu v-if="getAccess() == 1" index="1">
            <template slot="title">
              <i class="el-icon-sunny"></i>
              <span>数据管理</span>
            </template>
            <el-menu-item index="/grade">
              <i class="el-icon-basketball"></i>
              <span>班级列表</span>
            </el-menu-item>
            <el-menu-item index="/student">
              <i class="el-icon-basketball"></i>
              <span>学生列表</span>
            </el-menu-item>
            <el-menu-item index="/teacher">
              <i class="el-icon-basketball"></i>
              <span>教师列表</span>
            </el-menu-item>
            <el-menu-item index="/classroom">
              <i class="el-icon-basketball"></i>
              <span>教室列表</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-sunny"></i>
              <span>课程管理</span>
            </template>
            <el-menu-item v-if="getAccess() == 1" index="/requiredCourse">
              <i class="el-icon-basketball"></i>
              <span>必修课列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 1" index="/electiveCourse">
              <i class="el-icon-basketball"></i>
              <span>选修课列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/teacherCourse">
              <i class="el-icon-basketball"></i>
              <span>课程列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 1" index="/retakeCourse">
              <i class="el-icon-basketball"></i>
              <span>重修课列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 1" index="/electiveCourseAdd">
              <i class="el-icon-basketball"></i>
              <span>课程添加</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 1" index="/adjustCourse">
              <i class="el-icon-basketball"></i>
              <span>调课审核</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 2" index="/studentCourse">
              <i class="el-icon-basketball"></i>
              <span>课程列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 2" index="/studentSchedule">
              <i class="el-icon-basketball"></i>
              <span>课表查询</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/teacherSchedule">
              <i class="el-icon-basketball"></i>
              <span>课表查询</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/teacherAdjustCourse">
              <i class="el-icon-basketball"></i>
              <span>调课申请</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-sunny"></i>
              <span>选课管理</span>
            </template>
            <el-menu-item v-if="getAccess() == 1" index="/choice">
              <i class="el-icon-basketball"></i>
              <span>选课列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 2" index="/studentChoice">
              <i class="el-icon-basketball"></i>
              <span>选课列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/teacherChoice">
              <i class="el-icon-basketball"></i>
              <span>选课列表</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="7">
            <template slot="title">
              <i class="el-icon-sunny"></i>
              <span>考试管理</span>
            </template>
            <el-menu-item v-if="getAccess() == 1" index="/assignExam">
              <i class="el-icon-basketball"></i>
              <span>分配考试</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 1" index="/exam">
              <i class="el-icon-basketball"></i>
              <span>考试列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 2" index="/Studentexam">
              <i class="el-icon-basketball"></i>
              <span>考试列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/teacherExam">
              <i class="el-icon-basketball"></i>
              <span>考试列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/superviseExam">
              <i class="el-icon-basketball"></i>
              <span>监考列表</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="8">
            <template slot="title">
              <i class="el-icon-sunny"></i>
              <span>成绩管理</span>
            </template>
            <el-menu-item v-if="getAccess() == 1" index="/scoreStatus">
              <i class="el-icon-basketball"></i>
              <span>成绩状态</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 1" index="/score">
              <i class="el-icon-basketball"></i>
              <span>成绩列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 2" index="/studentScore">
              <i class="el-icon-basketball"></i>
              <span>成绩列表</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/teacherScore">
              <i class="el-icon-basketball"></i>
              <span>成绩列表</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="9">
            <template slot="title">
              <i class="el-icon-sunny"></i>
              <span>数据统计</span>
            </template>
            <el-menu-item v-if="getAccess() == 1" index="/chart">
              <i class="el-icon-basketball"></i>
              <span>数据图</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 2" index="/studentChart">
              <i class="el-icon-basketball"></i>
              <span>数据图</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/teacherChart">
              <i class="el-icon-basketball"></i>
              <span>数据图</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu v-if="getAccess() == 2" index="10">
            <template slot="title">
              <i class="el-icon-sunny"></i>
              <span>重修管理</span>
            </template>
            <el-menu-item index="/studentRetake">
              <i class="el-icon-basketball"></i>
              <span>重修选课</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="11">
            <template slot="title">
              <i class="el-icon-sunny"></i>
              <span>教学评价</span>
            </template>
            <el-menu-item v-if="getAccess() == 2" index="/studentEvaluation">
              <i class="el-icon-basketball"></i>
              <span>教师评价</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 1" index="/evaluation">
              <i class="el-icon-basketball"></i>
              <span>教师评价</span>
            </el-menu-item>
            <el-menu-item v-if="getAccess() == 3" index="/teacherevaluation">
              <i class="el-icon-basketball"></i>
              <span>教师评价</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main class="main">
        <!--添加学生对话框-->
        <el-dialog
          title="修改密码"
          :visible.sync="dialogFormVisibleAlter"
          :close-on-click-modal="false"
          center
          width="500px"
        >
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="原密码" prop="oldPassword">
              <el-input
                show-password
                v-model="ruleForm.oldPassword"
                style="width: 300px"
                placeholder="请输入原密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input
                show-password
                v-model="ruleForm.newPassword"
                style="width: 300px"
                placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button
              @click="resetForm('ruleForm')"
              type="danger"
              circle
              icon="el-icon-delete"
              style="margin-left: 300px !important"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-check"
              circle
              @click="submitForm('ruleForm')"
            ></el-button>
          </div>
        </el-dialog>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script type="text/javascript">
export default {
  name: "Home",
  data() {
    return {
      dialogFormVisibleAlter: false,
      ruleForm: {
        oldPassword: "",
        newPassword: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getAccess() {
      return sessionStorage.getItem("access");
    },
    getName() {
      return sessionStorage.getItem("name");
    },
    logout() {
      sessionStorage.clear();
      this.successMessage("退出成功");
      this.$router.push("login");
    },
    handleCommand(command) {
      if (command === "1") {
        this.logout();
      }
      if (command === "2") {
        this.dialogFormVisibleAlter = true;
      }
    },
    async alterPassword() {
      this.$axios({
        method: "post",
        url: "/alterPassword",
        data: {
          oldPassword: this.ruleForm.oldPassword,
          newPassword: this.ruleForm.newPassword,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.resetForm("ruleForm");
          this.dialogFormVisibleAlter = false;
        } else this.errorMessage(msg);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.alterPassword();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
h3,
h1 {
  color: white;
  font-weight: 500;
}
.container {
  height: 100vh;
}
.header {
  height: 20%;
  background-color: #0483d4;
}
.c {
  height: 80%;
}

.aside {
  height: 100%;
  overflow-y: auto;
  background-color: #d3dce6;
}

.main {
  background-color: #e9eef3;
  height: 100%;
}

.middle {
  text-align: center;
}

.name {
  margin-left: 20%;
}
</style>
