<template>
  <el-card v-if="choiceStatus" class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>选课管理</el-breadcrumb-item>
      <el-breadcrumb-item>选课列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入课程名称"
          @clear="loadChoiceList"
          clearable
          v-model="course"
          class="inputSearch"
          @keydown.enter.native="searchStudentChoice"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchStudentChoice"
          ></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-table :data="studentChoiceList" class="studentTable" max-height="434px">
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="120">
      </el-table-column>
      <el-table-column align="center" prop="name" label="课程名" width="130">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="120"
      >
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="120">
      </el-table-column>
      <el-table-column align="center" prop="from" label="起(周)" width="80">
      </el-table-column>
      <el-table-column align="center" prop="to" label="止(周)" width="80">
      </el-table-column>
      <el-table-column align="center" prop="week" label="星期" width="100">
      </el-table-column>
      <el-table-column align="center" prop="start" label="上(节)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="end" label="下(节)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="number" label="剩余" width="100">
      </el-table-column>
      <el-table-column align="center" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.flag === 0"
            type="primary"
            key="1"
            :disabled="!scope.row.disabled"
            @click="addStudentChoice(scope.row)"
            >选课</el-button
          >
          <el-button
            v-else-if="scope.row.flag === 1"
            type="danger"
            key="2"
            @click="deleteStudentChoice(scope.row)"
            >退选</el-button
          >
          <el-button
            v-if="scope.row.flag === 2"
            key="full"
            disabled
            type="warning"
            >已满</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "StudentChoice",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      //表格绑定的数据
      studentChoiceList: [],
      course: "",
      choiceStatus: false,
    };
  },
  created() {
    this.getStudentChoiceList();
  },
  methods: {
    async getStudentChoiceList() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/studentChoiceList",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.choiceStatus = true;
          this.studentChoiceList = data.studentChoiceList;
          this.total = data.count;
        } else {
          this.choiceStatus = false;
          this.errorMessage(msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "") this.getStudentChoiceList();
      else this.searchStudentChoice();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "") this.getStudentChoiceList();
      else this.searchStudentChoice();
    },
    async searchStudentChoice() {
      if (this.course === "") return;
      this.$axios({
        method: "get",
        url: "/searchStudentChoiceByCourse",
        params: {
          course: this.course,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.studentChoiceList = data.studentChoiceList;
          this.successMessage(msg);
        } else {
          this.errorMessage(msg);
          this.choiceStatus = false;
        }
      });
    },
    async loadChoiceList() {
      this.getStudentChoiceList();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
    async addStudentChoice(e) {
      this.$axios({
        method: "get",
        params: {
          id: e.id,
        },
        url: "/addStudentChoice",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.getStudentChoiceList();
        } else if (code === 300) {
          this.errorMessage(msg);
          this.getStudentChoiceList();
        } else if (code === 400) {
          this.errorMessage(msg);
        } else {
          this.choiceStatus = false;
          this.errorMessage(msg);
          this.choiceStatus = false;
        }
      });
    },
    async deleteStudentChoice(e) {
      this.$axios({
        method: "get",
        params: {
          id: e.id,
        },
        url: "/deleteStudentChoice",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.getStudentChoiceList();
        } else {
          this.errorMessage(msg);
          this.choiceStatus = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 434px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
</style>
