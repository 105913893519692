<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>考试管理</el-breadcrumb-item>
      <el-breadcrumb-item>分配考试</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入课程名"
          @clear="loadRequiredCourseList"
          clearable
          v-model="course"
          @keydown.enter.native="queryAssignExam"
          class="inputSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryAssignExam"
          ></el-button>
        </el-input>
        <el-button
          type="danger"
          @click="showAddExamDia"
          style="margin-left: 20px"
          :disabled="this.multipleSelection.length == 0"
          >添加考试</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="assignExamList"
      class="studentTable"
      ref="multipleTable"
      tooltip-effect="dark"
      :row-style="{ height: '65.2px' }"
      max-height="439px"
      :row-key="getRowKeys"
      @selection-change="handleSelectionChange"
      @row-click="handleSelection"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="180">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="180">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="170">
      </el-table-column>
      <el-table-column align="center" prop="grade" label="班级名" width="170">
      </el-table-column>
      <el-table-column align="center" prop="number" label="人数" width="170">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="170"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!--添加考试-->
    <el-dialog
      title="添加考试"
      :visible.sync="dialogCourseFormVisibleAdd"
      style="text-align: center; width: 80%; margin-left: 13%"
    >
      <el-form :model="examForm">
        <el-form-item label="教室" label-width="100px">
          <el-select
            style="width: 100%"
            v-model="examForm.classroom"
            filterable
            placeholder="请选择教室"
          >
            <el-option
              v-for="item in classroomList"
              :key="item.id"
              :label="item.classroom"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="监考老师" label-width="100px">
          <el-select
            style="width: 100%"
            v-model="examForm.supervisor"
            filterable
            placeholder="请选择监考老师"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.teacher"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" label-width="100px">
          <el-date-picker
            type="date"
            placeholder="请选择开始日期"
            v-model="examForm.date"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="时间" label-width="100px">
          <el-col :span="11">
            <el-time-picker
              v-model="examForm.start"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:00',
              }"
              value-format="HH:mm"
              format="HH:mm"
              placeholder="开始时间"
            >
            </el-time-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-time-picker
              v-model="examForm.end"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:00',
                minTime: examForm.start,
              }"
              value-format="HH:mm"
              format="HH:mm"
              placeholder="结束时间"
            >
            </el-time-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogCourseFormVisibleAdd = false;
            this.examList = [];
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addExam">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "AssignExam",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      //表格绑定的数据
      assignExamList: [],
      //添加对话框属性
      dialogCourseFormVisibleAdd: false,
      //添加用户的表单数据
      examForm: {
        classroom: "",
        supervisor: "",
        date: "",
        start: "",
        end: "",
      },
      number: 0,
      courseList: [],
      gradeList: [],
      classroomList: [],
      teacherList: [],
      course: "",
      multipleSelection: [],
    };
  },
  created() {
    this.queryAllAssignExam();
  },
  methods: {
    handleSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    async queryExamClassroomAndSupervisorByGrade() {
      this.multipleSelection.forEach((item) => {
        this.number += item.number;
      });
      this.$axios({
        method: "get",
        params: {
          number: this.number,
        },
        url: "/queryExamClassroomAndSupervisorByGrade",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.classroomList = data.classroomList;
          this.teacherList = data.teacherList;
          this.number = 0;
        }
      });
    },
    async addExam() {
      this.multipleSelection.forEach((item) => {
        this.gradeList.push(item.grade);
        this.courseList.push(item.id);
      });
      this.multipleSelection = [];
      this.$axios({
        method: "post",
        data: {
          gradeList: this.gradeList,
          courseList: this.courseList,
          supervisor: this.examForm.supervisor,
          classroom: this.examForm.classroom,
          date: this.examForm.date,
          start: this.examForm.start,
          end: this.examForm.end,
        },
        url: "/addExam",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.gradeList = [];
          this.courseList = [];
          this.examForm = {};
          this.successMessage(msg);
          this.dialogCourseFormVisibleAdd = false;
          this.toggleSelection();
          if (this.course === "") this.queryAllAssignExam();
          else this.queryAssignExam();
        } else {
          this.errorMessage(msg);
          this.gradeList = [];
          this.courseList = [];
          this.examForm = {};
          this.dialogCourseFormVisibleAdd = false;
          this.toggleSelection();
        }
      });
    },
    getRowKeys(row) {
      return row.id + row.grade;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    showAddExamDia() {
      this.dialogCourseFormVisibleAdd = true;
      this.queryExamClassroomAndSupervisorByGrade();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async queryAllAssignExam() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllAssignExam",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.assignExamList = data.assignExamList;
          this.total = data.count;
        }
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "") this.queryAllAssignExam();
      else this.queryAssignExam();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "") this.queryAllAssignExam();
      else this.queryAssignExam();
    },
    async queryAssignExam() {
      if (this.course === "") return;
      this.$axios({
        method: "get",
        url: "/queryAssignExam",
        params: {
          course: this.course,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.assignExamList = data.assignExamList;
          this.total = data.count;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadRequiredCourseList() {
      await this.queryAllAssignExam();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addRequiredCourse();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
</style>
