<template>
  <div>
    <el-card style="height: 100%">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>数据图</el-breadcrumb-item>
      </el-breadcrumb>
      <div
        id="echart"
        style="width: 100%; height: 550px; margin-top: 20px"
      ></div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Chart",
  mounted() {
    this.initCharts();
  },
  methods: {
    async initCharts() {
      const myChart = this.$echarts.init(document.getElementById("echart"));
      // 绘制图表
      let option1;
      await this.$axios({
        method: "get",
        url: "/queryChart",
      }).then((res) => {
        const { msg, data } = res.data;
        this.successMessage(msg);
        option1 = data.chartList;
      });
      const option = {
        title: {
          text: "成绩分布图",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        color: ["#ee6666", "#91cc75", "#fc8452", "#73c0de", "#ea7ccc"],
        series: [
          {
            type: "pie",
            data: option1,
            label: {
              show: true, // 默认 false
              formatter: "{b}({d}%)", //自定义显示格式(b:name, c:value, d:百分比)
            },
            stillShowZeroSum: false,
          },
        ],
      };
      myChart.setOption(option);
    },
    async queryChoiceGradeByCourse() {
      this.$axios({
        method: "get",
        params: {
          course: this.course,
        },
        url: "/queryChoiceGradeByCourse",
      }).then((res) => {
        const { data } = res.data;
        this.gradeList = data.gradeList;
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
</style>
