<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>考试管理</el-breadcrumb-item>
      <el-breadcrumb-item>考试列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="grade"
          filterable
          placeholder="请选择班级"
          clearable
          @clear="loadExamList"
        >
          <el-option
            v-for="item in gradeList"
            :key="item.id"
            :label="item.grade"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="danger"
          @click="queryExam"
          :disabled="this.grade == ''"
          style="margin-left: 20px"
          >搜索考试</el-button
        >
        <el-button
          type="primary"
          @click="dialogExcelFormVisible = true"
          icon="el-icon-download"
          id="addbutton"
          >导出</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="examList" class="examTable" max-height="439px">
      <el-table-column align="center" type="index" label="序号" width="60">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="110">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="110">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="60">
      </el-table-column>
      <el-table-column align="center" prop="grade" label="班级" width="110">
      </el-table-column>
      <el-table-column align="center" prop="number" label="人数" width="60">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="supervisor"
        label="监考老师"
        width="100"
      >
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="110">
      </el-table-column>
      <el-table-column align="center" prop="date" label="日期" width="100">
      </el-table-column>
      <el-table-column align="center" prop="start" label="开始时间" width="90">
      </el-table-column>
      <el-table-column align="center" prop="end" label="结束时间" width="90">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="showAlterExamDia(scope.row)"
            plain
            icon="el-icon-edit"
            circle
          ></el-button>
          <el-button
            type="danger"
            @click="showDeleteExamMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!--修改用户的对话框-->
    <el-dialog
      title="修改考试"
      :visible.sync="dialogFormVisibleAlter"
      style="text-align: center; width: 80%; margin-left: 10%; margin-top: -5%"
    >
      <el-form :model="form">
        <el-form-item label="考试课程" label-width="100px">
          <el-input
            style="width: 100%"
            v-model="form.course"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="任课老师" label-width="100px">
          <el-input
            style="width: 100%"
            v-model="form.teacher"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="班级" label-width="100px">
          <el-input
            v-model="form.grade"
            autocomplete="off"
            disabled
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="监考老师" prop="supervisor" label-width="100px">
          <el-select
            v-model="form.supervisor"
            placeholder="请选择监考老师"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.teacher"
              :value="item.teacher"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试教室" prop="classroom" label-width="100px">
          <el-select
            v-model="form.classroom"
            placeholder="请选择教室"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in classroomList"
              :key="item.id"
              :label="item.classroom"
              :value="item.classroom"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" label-width="100px">
          <el-date-picker
            type="date"
            placeholder="请选择开始日期"
            v-model="form.date"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="时间" label-width="100px">
          <el-col :span="11">
            <el-time-picker
              v-model="form.start"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:00',
              }"
              value-format="HH:mm"
              format="HH:mm"
              placeholder="开始时间"
            >
            </el-time-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-time-picker
              v-model="form.end"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:00',
                minTime: form.start,
              }"
              value-format="HH:mm"
              format="HH:mm"
              placeholder="结束时间"
            >
            </el-time-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAlter = false">取 消</el-button>
        <el-button type="primary" @click="alterExam">确 定</el-button>
      </div>
    </el-dialog>
    <!--修改用户的对话框-->
    <el-dialog
      title="导出学生Excel"
      :visible.sync="dialogExcelFormVisible"
      style="text-align: center; width: 70%; margin-left: 17%"
    >
      <el-form :model="form">
        <el-form-item label="起止时间" label-width="70px">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择开始日期"
              v-model="form.from"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择结结束日期"
              v-model="form.to"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExcelFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="queryExamExcelData">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
export default {
  name: "Exam",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      course: "",
      teacher: "",
      //表格绑定的数据
      examList: [],
      teacherList: [],
      requiredGradeList: [],
      classroomList: [],
      gradeList: [],
      //添加对话框属性
      dialogFormVisibleAdd: false,
      dialogFormVisibleAlter: false,
      dialogExcelFormVisible: false,
      excelData: [],
      //添加用户的表单数据
      form: {
        course: "",
        teacher: "",
        grade: "",
        supervisor: "",
        classroom: "",
        date: "",
        from: "",
        to: "",
      },
      grade: "",
    };
  },
  created() {
    this.queryAllExam();
  },
  methods: {
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        课程名: "course",
        班级: "grade",
        人数: "number",
        任课老师: "teacher",
        监考老师: "supervisor",
        教室: "classroom",
        日期: "date",
        开始时间: "start",
        结束时间: "end",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: `${this.form.from}-${this.form.to}考试安排表`,
        sheet: "考试安排",
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryExamExcelData() {
      this.$axios({
        method: "get",
        params: {
          from: this.form.from,
          to: this.form.to,
        },
        url: "/queryExamExcelData",
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.dialogExcelFormVisible = false;
        this.exportExcel();
        this.form = {};
      });
    },
    async queryAllExam() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllExam",
      }).then((res) => {
        const { data } = res.data;
        this.examList = data.examList;
        this.gradeList = data.gradeList;
        this.teacherList = data.teacherList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.grade === "") this.queryAllExam();
      else this.queryExam();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.grade === "") this.queryAllExam();
      else this.queryExam();
    },
    async queryExam() {
      this.$axios({
        method: "get",
        url: "/queryExam",
        params: {
          grade: this.grade,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.successMessage(msg);
          this.examList = data.examList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadExamList() {
      this.queryAllExam();
    },
    //添加用户表单
    showAddExamDia() {
      this.dialogFormVisibleAdd = true;
    },
    //删除用户盒子
    showDeleteExamMassage(e) {
      this.$confirm("删除该考试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteExam(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    async deleteExam(e) {
      this.$axios({
        method: "get",
        url: "/deleteExam",
        params: {
          course: e.id,
          grade: e.grade,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          if (this.grade === "") this.queryAllExam();
          else this.queryExam();
          this.successMessage(msg);
        }
      });
    },
    async queryExamClassroomAndSupervisorByGrade(exam) {
      this.$axios({
        method: "get",
        params: {
          number: exam.number,
        },
        url: "/queryExamClassroomAndSupervisorByGrade",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.classroomList = data.classroomList;
          this.teacherList = data.teacherList;
        }
      });
    },
    showAlterExamDia(exam) {
      this.form = JSON.parse(JSON.stringify(exam)); //深拷贝
      this.queryExamClassroomAndSupervisorByGrade(exam);
      //获取用户数据
      this.dialogFormVisibleAlter = true;
    },
    async alterExam() {
      this.$axios({
        method: "post",
        url: "/alterExam",
        data: {
          course: this.form.course,
          teacher: this.form.teacher,
          grade: this.form.grade,
          supervisor: this.form.supervisor,
          classroom: this.form.classroom,
          date: this.form.date,
          start: this.form.start,
          end: this.form.end,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.form = {};
          //更新视图
          this.queryAllExam();
          this.dialogFormVisibleAlter = false;
        } else this.errorMessage(msg);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addExam();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.examTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
