<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>调课申请</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="course"
          filterable
          placeholder="请选择课程"
          clearable
          @clear="queryTeacherAdjustCourse"
        >
          <el-option
            v-for="item in courseList"
            :key="item.course"
            :label="item.course"
            :value="item.course"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="resultList"
          filterable
          placeholder="请选择结果"
          clearable
          multiple
          @clear="queryTeacherAdjustCourse"
          style="margin-left: 20px; width: 250px"
        >
          <el-option label="待审核" value="0"></el-option>
          <el-option label="同意" value="1"></el-option>
          <el-option label="拒绝" value="2"></el-option>
        </el-select>
        <el-button
          type="primary"
          @click="queryTeacherAdjustCourse"
          :disabled="this.course === '' && this.resultList.length === 0"
          style="margin-left: 20px"
          >搜索</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="teacherAdjustCourseList"
      class="evaluationTable"
      ref="multipleTable"
      :row-style="{ height: '66px' }"
      max-height="444px"
    >
      <el-table-column align="center" prop="id" label="编号" width="80">
      </el-table-column>
      <el-table-column align="center" prop="tag" label="结果" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.result === 0">待审核</el-tag>
          <el-tag v-else-if="scope.row.result === 1" type="success"
            >同意</el-tag
          >
          <el-tag v-else-if="scope.row.result === 2" type="danger">拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程号" width="130">
      </el-table-column>
      <el-table-column align="center" prop="name" label="课程名" width="130">
      </el-table-column>
      <el-table-column align="center" prop="from" label="原周次" width="110">
      </el-table-column>
      <el-table-column align="center" prop="to" label="现周次" width="110">
      </el-table-column>
      <el-table-column align="center" prop="week" label="现星期" width="110">
      </el-table-column>
      <el-table-column align="center" prop="lesson" label="现节次" width="110">
      </el-table-column>
      <el-table-column align="center" prop="reason" label="原因" width="200">
      </el-table-column>
      <el-table-column align="center" label="操作" width="110">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            icon="el-icon-edit"
            circle
            @click="queryTeacherAdjustCourseForm(scope.row.id)"
            :disabled="scope.row.result === 1"
          ></el-button>
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            :disabled="scope.row.result === 1"
            @click="deleteTeacherAdjustCourse(scope.row.id)"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <el-dialog
      title="修改调课"
      :visible.sync="adjustCourseDialog"
      width="600px"
      center
    >
      <el-form
        :model="form"
        ref="form"
        label-position="left"
        label-width="60px"
      >
        <el-form-item label="原周次" prop="fromList">
          <el-row>
            <el-col>
              <el-checkbox-group v-model="form.fromList">
                <el-checkbox-button
                  v-for="city in number"
                  :label="city"
                  :key="city"
                  style="width: 48px"
                  :disabled="city < from || city > to"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col>
            <el-col>
              <el-checkbox-group v-model="form.fromList">
                <el-checkbox-button
                  v-for="city in number1"
                  :label="city"
                  :key="city"
                  style="width: 48px"
                  :disabled="city < from || city > to"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col></el-row
          >
        </el-form-item>
        <el-form-item label="现周次" prop="toList">
          <el-row>
            <el-col>
              <el-checkbox-group v-model="form.toList">
                <el-checkbox-button
                  v-for="city in number"
                  :label="city"
                  :key="city"
                  style="width: 48px"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col>
            <el-col>
              <el-checkbox-group v-model="form.toList">
                <el-checkbox-button
                  v-for="city in number1"
                  :label="city"
                  :key="city"
                  style="width: 48px"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col></el-row
          >
        </el-form-item>
        <el-form-item label="现星期" prop="weekList">
          <el-checkbox-group
            v-model="form.weekList"
            style="margin-left: -500px !important"
          >
            <el-checkbox-button
              v-for="city in week"
              :label="city"
              :key="city"
              style="width: 48px"
              >{{ city }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="现节次" prop="lessonList">
          <el-checkbox-group v-model="form.lessonList">
            <el-checkbox-button
              v-for="city in number"
              :label="city"
              :key="city"
              style="width: 48px"
              >{{ city }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="原因">
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: -5%; margin-left: 400px"
      >
        <el-button @click="adjustCourseDialog = false">取 消</el-button>
        <el-button type="primary" @click="updateAdjustCourse">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "TeacherAdjustCourse",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      teacherAdjustCourseList: [],
      courseList: [],
      course: "",
      resultList: "",
      from: "",
      to: "",
      form: {
        id: "",
        course: "",
        fromList: [],
        toList: [],
        weekList: [],
        lessonList: [],
        reason: "",
      },
      adjustCourseDialog: false,
      number: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      number1: ["11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
      week: ["1", "2", "3", "4", "5", "6", "7"],
    };
  },
  created() {
    this.queryAllTeacherAdjustCourse();
  },
  methods: {
    async deleteTeacherAdjustCourse(id) {
      this.$axios({
        method: "get",
        url: `/deleteTeacherAdjustCourse/${id}`,
      }).then((res) => {
        const { msg } = res.data;
        this.successMessage(msg);
        if (this.course === "" && this.resultList.length === 0)
          this.queryAllTeacherAdjustCourse();
        else this.queryTeacherAdjustCourse();
      });
    },
    async updateAdjustCourse() {
      this.$axios({
        method: "post",
        url: "/updateAdjustCourse",
        data: {
          id: this.form.id,
          course: this.form.course,
          fromList: this.form.fromList,
          toList: this.form.toList,
          weekList: this.form.weekList,
          lessonList: this.form.lessonList,
          reason: this.form.reason,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.adjustCourseDialog = false;
          this.$refs["form"].resetFields();
          if (this.course === "" && this.resultList.length === 0)
            this.queryAllTeacherAdjustCourse();
          else this.queryTeacherAdjustCourse();
        }
      });
    },
    async queryTeacherAdjustCourseForm(id) {
      this.$axios({
        method: "get",
        url: `/queryTeacherAdjustCourseForm/${id}`,
      }).then((res) => {
        const { data } = res.data;
        this.form = data.form;
        this.from = data.from;
        this.to = data.to;
        this.adjustCourseDialog = true;
      });
    },
    async queryAllTeacherAdjustCourse() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllTeacherAdjustCourse",
      }).then((res) => {
        const { data } = res.data;
        this.teacherAdjustCourseList = data.teacherAdjustCourseList;
        this.courseList = data.courseList;
        this.total = data.count;
      });
    },
    async queryTeacherAdjustCourse() {
      this.$axios({
        method: "post",
        url: `/queryTeacherAdjustCourse/${this.pagenum}/${this.pagesize}`,
        data: {
          course: this.course,
          resultList: this.resultList,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.teacherAdjustCourseList = data.teacherAdjustCourseList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "" && this.resultList.length === 0)
        this.queryAllTeacherAdjustCourse();
      else this.queryTeacherAdjustCourse();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "" && this.resultList.length === 0)
        this.queryAllTeacherAdjustCourse();
      else this.queryTeacherAdjustCourse();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.evaluationTable {
  width: 100%;
  height: 444px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
.el-tag {
  width: 60px;
}
</style>
