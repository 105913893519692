<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>成绩管理</el-breadcrumb-item>
      <el-breadcrumb-item>成绩列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="course"
          filterable
          placeholder="请选择课程"
          clearable
          @clear="queryAllTeacherScore"
        >
          <el-option
            v-for="item in courseList"
            :key="item.id"
            :label="item.course"
            :value="item.course"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="queryTeacherScore"
          style="margin-left: 20px"
          :disabled="this.course === ''"
          >搜索课程</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="teacherCourseList" class="examTable" max-height="434.5px">
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="110">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="140">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="80">
      </el-table-column>
      <el-table-column align="center" prop="tag" label="课程类型" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.kind === 1">必修</el-tag>
          <el-tag v-else-if="scope.row.kind === 2" type="success">选修</el-tag>
          <el-tag v-else-if="scope.row.kind === 3" type="danger">重修</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="grade" label="上课班级" width="320">
      </el-table-column>
      <el-table-column align="center" prop="number" label="人数" width="80">
      </el-table-column>
      <el-table-column label="公开成绩" align="center" width="170">
        <template slot-scope="scope">
          <el-switch
            @change="changeScoreStatus(scope.row)"
            :value="scope.row.score"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="90">
        <template slot-scope="scope">
          <el-button type="danger" @click="showAddScore(scope.row.id)"
            >录入</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "TeacherScore",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      course: "",
      //表格绑定的数据
      courseList: [],
      teacherCourseList: [],
    };
  },
  created() {
    this.queryAllTeacherScore();
  },
  methods: {
    async queryAllTeacherScore() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllTeacherScore",
      }).then((res) => {
        const { data } = res.data;
        this.teacherCourseList = data.teacherCourseList;
        this.courseList = data.courseList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "") this.queryAllTeacherScore();
      else this.queryTeacherScore();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "") this.queryAllTeacherScore();
      else this.queryTeacherScore();
    },
    async changeScoreStatus(e) {
      this.$axios({
        method: "get",
        params: {
          course: e.id,
          score: e.score,
        },
        url: "/changeScoreStatus",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          e.score = !e.score;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async queryTeacherScore() {
      this.$axios({
        method: "get",
        url: "/queryTeacherScore",
        params: {
          course: this.course,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.successMessage(msg);
          this.teacherCourseList = data.teacherCourseList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadExamList() {
      await this.queryAllTeacherScore();
    },
    showAddScore(course) {
      this.$router.push({ path: "/addScore", query: { course: course } });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.examTable {
  width: 100%;
  height: 434.5px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}

.el-table >>> .warning-row {
  color: red;
  /*  font-weight:bold;*/
}
::v-deep .input .el-input__inner {
  width: 100%;
  text-align: center;
}
</style>
