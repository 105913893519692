<template>
  <div>
    <div class="panel">
      <el-table
        :data="timetable"
        :span-method="objectSpanMethod"
        border
        :header-cell-style="{
          background: '#d9e5fd',
          color: 'black',
          fontWeight: 1000,
        }"
        :cell-style="tableCellStyle"
      >
        <el-table-column prop="sjd" label="时间段" width="80" align="center">
        </el-table-column>
        <el-table-column prop="jc" label="节次" width="80" align="center">
        </el-table-column>
        <el-table-column prop="mon" label="星期一" align="center">
          <template slot-scope="scope" v-if="scope.row.mon.from != undefined">
            {{ scope.row.mon.course }}
            <br />
            {{ scope.row.mon.classroom }}
            {{ scope.row.mon.from }}-{{ scope.row.mon.to }}周
          </template>
        </el-table-column>
        <el-table-column prop="tue" label="星期二" align="center">
          <template slot-scope="scope" v-if="scope.row.tue.from != undefined">
            {{ scope.row.tue.course }}
            <br />
            {{ scope.row.tue.classroom }}
            {{ scope.row.tue.from }}-{{ scope.row.tue.to }}周
          </template>
        </el-table-column>
        <el-table-column prop="wed" label="星期三" align="center">
          <template slot-scope="scope" v-if="scope.row.wed.from != undefined">
            {{ scope.row.wed.course }}
            <br />
            {{ scope.row.wed.classroom }}
            {{ scope.row.wed.from }}-{{ scope.row.wed.to }}周
          </template>
        </el-table-column>
        <el-table-column prop="thu" label="星期四" align="center">
          <template slot-scope="scope" v-if="scope.row.thu.from != undefined">
            {{ scope.row.thu.course }}
            <br />
            {{ scope.row.thu.classroom }}
            {{ scope.row.thu.from }}-{{ scope.row.thu.to }}周
          </template>
        </el-table-column>
        <el-table-column prop="fri" label="星期五" align="center">
          <template slot-scope="scope" v-if="scope.row.fri.from != undefined">
            {{ scope.row.fri.course }}
            <br />
            {{ scope.row.fri.classroom }}
            {{ scope.row.fri.from }}-{{ scope.row.fri.to }}周
          </template>
        </el-table-column>
        <el-table-column prop="sat" label="星期六" align="center">
          <template slot-scope="scope" v-if="scope.row.sat.from != undefined">
            {{ scope.row.sat.course }}
            <br />
            {{ scope.row.sat.classroom }}
            {{ scope.row.sat.from }}-{{ scope.row.sat.to }}周
          </template>
        </el-table-column>
        <el-table-column prop="sun" label="星期日" align="center">
          <template slot-scope="scope" v-if="scope.row.sun.from != undefined">
            {{ scope.row.sun.course }}
            <br />
            {{ scope.row.sun.classroom }}
            {{ scope.row.sun.from }}-{{ scope.row.sun.to }}周
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "Schedule",
  data() {
    return {
      afternoonLength: 4,
      length: 10,
      // 课程表数据
      timetable: [],
      scheduleList: [],
      hoverOrderArr: [],
      weeks: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
    };
  },
  mounted() {
    this.mergeData();
  },
  watch: {
    scheduleList: {
      handler(newVal, oldVal) {
        this.mergeData();
      },
      deep: true,
    },
  },
  created() {
    this.queryTeacherSchedule();
  },
  methods: {
    async queryTeacherSchedule() {
      await this.$axios({
        method: "get",
        url: "/queryTeacherSchedule",
      }).then((res) => {
        const { data } = res.data;
        this.scheduleList = data.scheduleList;
        this.makeTimetable();
        this.mergeData();
      });
    },
    // 单元格添加背景色
    tableCellStyle(row) {
      if (row.row[row.column.property].kind === 1) {
        return "background-color:deepskyblue; border-radius:10px";
      } else if (row.row[row.column.property].kind === 2) {
        return "background-color:#1bd1a5; border-radius:10px";
      } else if (row.row[row.column.property].kind === 3) {
        return "background-color:#f47983; border-radius:10px";
      }
    },
    // 构造课程表完整数据
    makeTimetable() {
      this.timetable = [];
      for (let i = 0; i < this.length; i++) {
        let one = {
          sjd: "",
          jc: i + 1,
          mon: {},
          tue: {},
          wed: {},
          thu: {},
          fri: {},
          sat: {},
          sun: {},
        };
        if (i < 4) {
          one.sjd = "上午";
        } else if (i > 3 && i < this.afternoonLength + 4) {
          one.sjd = "下午";
        } else {
          one.sjd = "晚上";
        }
        this.timetable.push(one);
      }
    },
    mergeData() {
      // 合并数据
      if (this.scheduleList.length > 0) {
        for (let i = 0; i < this.scheduleList.length; i++) {
          // 获取星期几
          let week = this.weeks[this.scheduleList[i].week - 1];
          this.timetable[this.scheduleList[i].start - 1][week] =
            this.scheduleList[i];
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex < 4) {
          if (rowIndex === 0) {
            return {
              rowspan: 4,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        } else if (rowIndex > 3 && rowIndex < 4 + this.afternoonLength) {
          if (rowIndex === 4) {
            return {
              rowspan: this.afternoonLength,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        } else {
          if (rowIndex === 4 + this.afternoonLength) {
            return {
              rowspan: this.length - 4 - this.afternoonLength,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        }
      }
      if (columnIndex === 2) {
        if (row.mon.course !== undefined) {
          return {
            rowspan: row.mon.end - row.mon.start + 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 3) {
        if (row.tue.course !== undefined) {
          return {
            rowspan: row.tue.end - row.tue.start + 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 4) {
        if (row.wed.course !== undefined) {
          return {
            rowspan: row.wed.end - row.wed.start + 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 5) {
        if (row.thu.course !== undefined) {
          return {
            rowspan: row.thu.end - row.thu.start + 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 6) {
        if (row.fri.course !== undefined) {
          return {
            rowspan: row.fri.end - row.fri.start + 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 7) {
        if (row.sat.course !== undefined) {
          return {
            rowspan: row.sat.end - row.sat.start + 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 8) {
        if (row.sun.course !== undefined) {
          return {
            rowspan: row.sun.end - row.sun.start + 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
    },
  },
};
</script>
<style scoped>
.panel {
  height: 100%;
}
</style>
