<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>成绩管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加成绩</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row style="margin-top: 30px">
      <el-col :span="7"
        >平时成绩占比<el-input
          v-model="usual"
          placeholder="请输入平时成绩占比"
          style="width: 200px; margin-left: 5px; margin-right: 5px"
        ></el-input
        >%</el-col
      >
      <el-col :span="7"
        >实验成绩占比<el-input
          v-model="experiment"
          placeholder="请输入实验成绩占比"
          style="width: 200px; margin-left: 5px; margin-right: 5px"
        ></el-input
        >%</el-col
      >
      <el-col :span="7"
        >期末成绩占比<el-input
          v-model="exam"
          placeholder="请输入期末成绩占比"
          style="width: 200px; margin-left: 5px; margin-right: 5px"
        ></el-input
        >%</el-col
      >
      <el-col :span="3">
        <el-button type="danger" @click="addScore">保存成绩</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="studentList"
      class="examTable"
      :row-class-name="tableRowClassName"
      max-height="500px"
    >
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="id" label="学号" width="150">
      </el-table-column>
      <el-table-column align="center" prop="student" label="姓名" width="100">
      </el-table-column>
      <el-table-column align="center" prop="grade" label="班级" width="100">
      </el-table-column>
      <el-table-column align="center" label="平时成绩" width="200">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.usual"
            placeholder="请输入平时成绩"
            class="input"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" label="实验成绩" width="200">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.experiment"
            placeholder="请输入实验成绩"
            :disabled="experiment == 0"
            class="input"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" label="期末成绩" width="200">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.exam"
            placeholder="请输入期末成绩"
            class="input"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="总分"
        width="150"
        sortable
        :sort-method="sortNumber"
      >
        <template slot-scope="scope">
          {{ total(scope.row) }}
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "AddScore",
  data() {
    return {
      //表格绑定的数据
      course: "",
      studentList: [],
      usual: "",
      exam: "",
      experiment: "",
    };
  },
  created() {
    this.course = this.$route.query.course;
    this.queryTeacherScoreStudentList(this.course);
  },
  methods: {
    sortNumber(a, b) {
      return a.total - b.total;
    },
    total(score) {
      let final = 0;
      if (score.usual !== undefined) {
        if (score.usual === "") score.usual = undefined;
        score.usual = Number(score.usual);
        final += score.usual * Number(this.usual);
        if (isNaN(score.usual)) score.usual = undefined;
      }

      if (score.experiment !== undefined) {
        if (score.experiment === "") score.experiment = undefined;
        score.experiment = Number(score.experiment);
        final += score.experiment * Number(this.experiment);
        if (isNaN(score.experiment)) score.experiment = undefined;
      }
      if (score.exam !== undefined) {
        if (score.exam === "") score.exam = undefined;
        score.exam = Number(score.exam);
        final += score.exam * Number(this.exam);
        if (isNaN(score.exam)) score.exam = undefined;
      }
      score.total = Math.round(final / 100);
      return score.total;
    },
    tableRowClassName({ row }) {
      if (row.total < 60) {
        return "warning-row";
      }
    },
    async queryTeacherScoreStudentList() {
      this.$axios({
        method: "get",
        params: {
          course: this.course,
        },
        url: "/queryTeacherScoreStudentList",
      }).then((res) => {
        const { data } = res.data;
        this.studentList = data.studentList;
        this.usual = data.usual;
        this.exam = data.exam;
        this.experiment = data.experiment;
      });
    },
    async addScore() {
      this.$axios({
        method: "post",
        url: `/addScore/${this.course}/${this.usual}/${this.experiment}/${this.exam}`,
        data: {
          studentList: this.studentList,
        },
      }).then((res) => {
        const { msg } = res.data;
        this.successMessage(msg);
        this.$router.push({ path: "/teacherScore" });
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.examTable {
  width: 100%;
  overflow: auto;
  margin-top: 20px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}

.el-table >>> .warning-row {
  color: red;
  /*  font-weight:bold;*/
}
::v-deep .input .el-input__inner {
  width: 100%;
  text-align: center;
}
</style>
