<template>
  <div>
    <el-card class="box-card">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>课程管理</el-breadcrumb-item>
        <el-breadcrumb-item>课程添加</el-breadcrumb-item>
      </el-breadcrumb>
      <!--提示-->
      <el-alert
        class="alert"
        title="添加课程信息"
        type="success"
        center
        show-icon
      >
      </el-alert>
      <!--步骤条-->
      <el-steps
        :active="1 * active"
        finish-status="success"
        simple
        style="margin-top: 20px"
      >
        <el-step title="基本信息"></el-step>
        <el-step title="详细信息"></el-step>
      </el-steps>
      <el-form
        label-position="top"
        label-width="80px"
        :model="form"
        style="height: 477px"
      >
        <el-tabs v-model="active" tab-position="left">
          <el-tab-pane name="0" label="基本信息">
            <el-form-item label="课程号" label-width="100px">
              <el-input v-model="form.id" placeholder="请输入课程号"></el-input>
            </el-form-item>
            <el-form-item label="课程名称" label-width="100px">
              <el-input
                v-model="form.name"
                placeholder="请输入课程名称"
              ></el-input>
            </el-form-item>
            <el-col :span="8">
              <el-form-item label="任课老师" label-width="100px">
                <el-select v-model="form.teacher" placeholder="请选择">
                  <el-option
                    v-for="item in teacherList"
                    :key="item.id"
                    :label="item.teacher"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="类型" prop="sex">
                <el-select v-model="form.kind" placeholder="请选择类型">
                  <el-option label="选修" value="选修"></el-option>
                  <el-option label="重修" value="重修"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-form-item label="起止时间" label-width="100px">
              <el-col :span="6">
                <el-date-picker
                  type="date"
                  placeholder="请选择开始日期"
                  v-model="form.from"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="16">
                <el-date-picker
                  type="date"
                  placeholder="请选择结结束日期"
                  v-model="form.to"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane name="1" label="详细信息">
            <el-col :span="8">
              <el-form-item label="人数" label-width="100px">
                <el-input
                  v-model="form.number"
                  placeholder="请输入上课人数"
                  style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="学分" label-width="100px">
                <el-input
                  v-model="form.credit"
                  placeholder="请输入课程学分"
                  style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="上课节次">
                <el-select
                  v-model="form.start"
                  placeholder="请选择上课节次"
                  style="width: 300px"
                >
                  <el-option label="1" value="1"></el-option>
                  <el-option label="2" value="2"></el-option>
                  <el-option label="3" value="3"></el-option>
                  <el-option label="4" value="4"></el-option>
                  <el-option label="5" value="5"></el-option>
                  <el-option label="6" value="6"></el-option>
                  <el-option label="7" value="7"></el-option>
                  <el-option label="8" value="8"></el-option>
                  <el-option label="9" value="9"></el-option>
                  <el-option label="10" value="10"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="下课节次">
                <el-select
                  v-model="form.end"
                  placeholder="请选择下课节次"
                  style="width: 300px"
                >
                  <el-option label="1" value="1"></el-option>
                  <el-option label="2" value="2"></el-option>
                  <el-option label="3" value="3"></el-option>
                  <el-option label="4" value="4"></el-option>
                  <el-option label="5" value="5"></el-option>
                  <el-option label="6" value="6"></el-option>
                  <el-option label="7" value="7"></el-option>
                  <el-option label="8" value="8"></el-option>
                  <el-option label="9" value="9"></el-option>
                  <el-option label="10" value="10"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="星期">
                <el-select
                  v-model="form.week"
                  placeholder="请选择星期"
                  style="width: 300px"
                >
                  <el-option label="星期一" value="1"></el-option>
                  <el-option label="星期二" value="2"></el-option>
                  <el-option label="星期三" value="3"></el-option>
                  <el-option label="星期四" value="4"></el-option>
                  <el-option label="星期五" value="5"></el-option>
                  <el-option label="星期六" value="6"></el-option>
                  <el-option label="星期日" value="7"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="教室" label-width="100px">
                <el-select
                  v-model="form.classroom"
                  placeholder="请选择"
                  style="width: 300px"
                >
                  <el-option
                    v-for="item in classroomList"
                    :key="item.id"
                    :label="item.classroom"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-button
              type="primary"
              @click="addCourse"
              style="margin-top: 30px"
              >添加课程</el-button
            >
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ElectiveCourseAdd",
  data() {
    return {
      active: "0",
      teacherList: [],
      classroomList: [],
      form: {
        id: "",
        name: "",
        teacher: "",
        week: "",
        start: "",
        end: "",
        classroom: "",
        number: "",
        kind: "",
        from: "",
        to: "",
        credit: "",
      },
    };
  },
  created() {
    this.queryAllTeacherIdAndName();
    this.queryAllClassroomIdAndName();
  },
  methods: {
    async queryAllTeacherIdAndName() {
      this.$axios({
        method: "get",
        url: "/queryAllTeacherIdAndName",
      }).then((res) => {
        const { data } = res.data;
        this.teacherList = data.teacherList;
      });
    },
    async queryAllClassroomIdAndName() {
      this.$axios({
        method: "get",
        url: "/queryAllClassroomIdAndName",
      }).then((res) => {
        const { data } = res.data;
        this.classroomList = data.classroomList;
      });
    },
    async addCourse() {
      if (this.form.kind === "选修") this.addElectiveCourse();
      else if (this.form.kind === "重修") this.addRetakeCourse();
    },
    async addElectiveCourse() {
      this.$axios({
        method: "post",
        url: "/addElectiveCourse",
        data: {
          id: this.form.id,
          name: this.form.name,
          teacher: this.form.teacher,
          week: Number(this.form.week),
          start: Number(this.form.start),
          end: Number(this.form.end),
          classroom: this.form.classroom,
          number: Number(this.form.number),
          from: this.form.from,
          to: this.form.to,
          credit: Number(this.form.credit),
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.form = {};
          this.active = "0";
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async addRetakeCourse() {
      this.$axios({
        method: "post",
        url: "/addRetakeCourse",
        data: {
          id: this.form.id,
          name: this.form.name,
          teacher: this.form.teacher,
          week: Number(this.form.week),
          start: Number(this.form.start),
          end: Number(this.form.end),
          classroom: this.form.classroom,
          number: Number(this.form.number),
          from: this.form.from,
          to: this.form.to,
          credit: Number(this.form.credit),
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.form = {};
          this.active = "0";
        } else {
          this.errorMessage(msg);
        }
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.alert {
  margin-top: 10px;
}

.box-card {
  height: 100%;
}
</style>
