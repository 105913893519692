<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>教学评价</el-breadcrumb-item>
      <el-breadcrumb-item>教师评价</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="course"
          filterable
          placeholder="请选择课程"
          clearable
          @change="queryTeacherChoiceGradeByCourse"
        >
          <el-option
            v-for="item in courseList"
            :key="item.id"
            :label="item.course"
            :value="item.course"
          >
          </el-option>
        </el-select>
        <el-select
          style="margin-left: 20px"
          v-model="grade"
          placeholder="请选择班级"
          clearable
          filterable
          @change="queryChoiceCourseByGrade"
        >
          <el-option
            v-for="item in gradeList"
            :key="item.id"
            :label="item.grade"
            :value="item.grade"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="queryTeacherEvaluation"
          :disabled="course === '' && grade === ''"
          style="margin-left: 20px"
          >搜索评价</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="evaluationList"
      class="evaluationTable"
      :row-style="{ height: '65.2px' }"
      max-height="439px"
    >
      <el-table-column align="center" type="index" label="序号" width="160">
      </el-table-column>
      <el-table-column align="center" prop="student" label="学生" width="160">
      </el-table-column>
      <el-table-column align="center" prop="grade" label="班级" width="160">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程" width="160">
      </el-table-column>
      <el-table-column align="center" label="评价" width="270">
        <template slot-scope="scope">
          <el-rate v-model="scope.row.evaluation" show-text disabled> </el-rate>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="comment" label="评语" width="320">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "TeacherEvaluation",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      evaluationList: [],
      courseList: [],
      gradeList: [],
      grade: "",
      course: "",
    };
  },
  created() {
    this.queryAllTeacherEvaluation();
  },
  methods: {
    async queryAllTeacherEvaluation() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllTeacherEvaluation",
      }).then((res) => {
        const { data } = res.data;
        this.evaluationList = data.evaluationList;
        this.courseList = data.courseList;
        this.gradeList = data.gradeList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "" && this.grade === "")
        this.queryAllTeacherEvaluation();
      else this.queryTeacherEvaluation();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "" && this.grade === "")
        this.queryAllTeacherEvaluation();
      else this.queryTeacherEvaluation();
    },
    async queryTeacherEvaluation() {
      this.pagenum = 1;
      this.$axios({
        method: "get",
        url: "/queryTeacherEvaluation",
        params: {
          course: this.course,
          grade: this.grade,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.successMessage(msg);
          this.evaluationList = data.evaluationList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadExamList() {
      this.queryAllTeacherEvaluation();
    },
    async queryTeacherChoiceGradeByCourse() {
      this.$axios({
        method: "get",
        params: {
          course: this.course,
        },
        url: "/queryTeacherChoiceGradeByCourse",
      }).then((res) => {
        const { data } = res.data;
        this.gradeList = data.gradeList;
      });
    },
    async queryChoiceCourseByGrade() {
      this.$axios({
        method: "get",
        params: {
          grade: this.grade,
        },
        url: "/queryChoiceCourseByGrade",
      }).then((res) => {
        const { data } = res.data;
        this.courseList = data.courseList;
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.evaluationTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
