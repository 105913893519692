<template>
  <div class="login-wrap">
    <el-form
      class="login-form"
      label-position="left"
      label-width="50px"
      :model="formLabelAlign"
      :rules="rules"
      ref="formLabelAlign"
      :hide-required-asterisk="true"
    >
      <h2 style="text-align: center">用户登录</h2>
      <el-form-item label="帐号" prop="account">
        <el-input
          v-model="formLabelAlign.account"
          @keydown.enter.native="login('formLabelAlign')"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          show-password
          type="password"
          v-model="formLabelAlign.password"
          @keydown.enter.native="login('formLabelAlign')"
        ></el-input>
      </el-form-item>
      <el-button
        class="login-btn"
        type="primary"
        @click="login('formLabelAlign')"
        >登录</el-button
      >
      <el-button
        type="text"
        style="display: block; margin: 0 auto; margin-top: 10px"
        @click="resetPasswordDialog = true"
        >已有帐号，忘记密码？</el-button
      >
    </el-form>
    <el-dialog
      title="重置密码"
      :visible.sync="resetPasswordDialog"
      style="text-align: center"
      width="600px"
    >
      <el-form :model="resetForm" label-width="60px" ref="resetForm">
        <el-form-item label="帐号" style="margin-top: 35px">
          <el-input v-model="resetForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" style="margin-top: 35px">
          <el-input v-model="resetForm.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" style="margin-top: 35px">
          <el-input
            v-model="resetForm.password"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-row style="margin-top: 35px">
          <el-col :span="18">
            <el-form-item label="验证码">
              <el-input
                v-model="resetForm.code"
                autocomplete="off"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="5" style="margin-left: 4%">
            <el-button
              type="danger"
              v-show="show"
              @click="sendCode"
              :disabled="
                resetForm.account === '' ||
                resetForm.account === undefined ||
                resetForm.email === '' ||
                resetForm.email === undefined
              "
              >获取验证码</el-button
            >
            <el-button type="danger" disabled v-show="!show">{{
              text
            }}</el-button></el-col
          >
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetPasswordDialog = false">取 消</el-button>
        <el-button type="primary" @click="resetPassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "UserLogin",
  data() {
    return {
      formLabelAlign: {
        account: "",
        password: "",
      },
      resetForm: {
        account: "",
        password: "",
        code: "",
        email: "",
      },
      show: true,
      time: null, //设置计时器,
      count: 0,
      text: "倒计时60秒",
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      resetPasswordDialog: false,
    };
  },
  methods: {
    async sendCode() {
      this.$axios({
        method: "get",
        url: `/sendCode/${this.resetForm.account}/${this.resetForm.email}`,
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 500) this.errorMessage(msg);
        else {
          this.successMessage(msg);
          const CountDown = 60;
          if (!this.time) {
            this.count = CountDown;
            this.show = false;
            this.time = setInterval(() => {
              if (this.count > 1 && this.count <= CountDown) {
                this.count--;
                this.text = `倒计时${this.count}秒`;
              } else {
                this.show = true;
                clearInterval(this.time);
                this.time = null;
                this.text = `倒计时60秒`;
              }
            }, 1000);
          }
        }
      });
    },
    async resetPassword() {
      this.$axios({
        method: "post",
        url: "/resetPassword",
        data: {
          account: this.resetForm.account,
          password: this.resetForm.password,
          code: this.resetForm.code
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 500) this.errorMessage(msg);
        else {
          this.successMessage(msg);
          this.resetForm = {};
          clearInterval(this.time);
          this.time = null;
          this.show = true;
          this.resetPasswordDialog = false;
          this.text = `倒计时60秒`;
        }
      });
    },
    async login(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios({
            url: "/login",
            method: "post",
            data: {
              account: this.formLabelAlign.account,
              password: this.formLabelAlign.password,
            },
          }).then((res) => {
            const { code, msg, data } = res.data;
            if (code === 200) {
              this.successMessage(msg);
              sessionStorage.setItem("token", data.token);
              sessionStorage.setItem("name", data.name);
              sessionStorage.setItem("access", data.access);
              this.$router.push({ path: "/" });
            } else this.errorMessage(msg);
          });
        } else {
          return false;
        }
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.login-wrap {
  height: 100vh;
  background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  width: 500px;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  transition: transform 0.3s ease-in-out,
    box-shadow 0.3s cubic-bezier(0.47, 0, 0.745, 0.715), border 0.3s linear 0.1s;
}
.login-form:hover {
  box-shadow: 0 10px 100px rgba(51, 51, 51, 0.25);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
}
.login-btn {
  width: 100%;
  margin-top: 20px;
  margin-left: 0 !important;
}
</style>
