import VueRouter from "vue-router";
import UserLogin from "../components/UserLogin";
import Home from "../components/Home";
import Student from "../components/StudentManage/Student";
import Teacher from "../components/TeacherManage/Teacher";
import Classroom from "../components/ClassroomManage/Classroom";
import ElectiveCourse from "../components/CourseManage/ElectiveCourse";
import RequiredCourse from "../components/CourseManage/RequiredCourse";
import TeacherCourse from "../components/CourseManage/TeacherCourse";
import RetakeCourse from "../components/CourseManage/RetakeCourse";
import ElectiveCourseAdd from "../components/CourseManage/ElectiveCourseAdd";
import AdjustCourse from "../components/CourseManage/AdjustCourse";
import TeacherAdjustCourse from "../components/CourseManage/TeacherAdjustCourse";
import StudentSchedule from "../components/CourseManage/StudentSchedule";
import StudentCourse from "../components/CourseManage/StudentCourse";
import Choice from "../components/ChoiceManage/Choice";
import StudentChoice from "../components/ChoiceManage/StudentChoice";
import TeacherChoice from "../components/ChoiceManage/TeacherChoice";
import TeacherSchedule from "../components/CourseManage/TeacherSchedule";
import StudnetExam from "../components/ExamManage/StudentExam";
import Exam from "../components/ExamManage/Exam";
import AssignExam from "../components/ExamManage/AssignExam";
import TeacherExam from "../components/ExamManage/TeacherExam";
import SuperviseExam from "../components/ExamManage/SuperviseExam";
import StudentScore from "../components/ScoreManage/StudentScore";
import TeacherScore from "../components/ScoreManage/TeacherScore";
import AddScore from "../components/ScoreManage/AddScore";
import Score from "../components/ScoreManage/Score";
import ScoreStatus from "../components/ScoreManage/ScoreStatus";
import Grade from "../components/GradeManage/Grade";
import StudentChart from "../components/ChartManage/StudentChart";
import TeacherChart from "../components/ChartManage/TeacherChart";
import Chart from "../components/ChartManage/Chart";
import StudentRetake from "../components/RetakeManage/StudentRetake";
import StudentEvaluation from "../components/EvaluationManage/StudentEvaluation";
import Evaluation from "../components/EvaluationManage/Evaluation";
import TeacherEvaluation from "../components/EvaluationManage/TeacherEvaluation";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      name: "login",
      path: "/login",
      component: UserLogin,
    },
    {
      name: "home",
      path: "/",
      component: Home,
      children: [
        {
          name: "grade",
          path: "/grade",
          component: Grade,
        },
        {
          name: "student",
          path: "/student",
          component: Student,
        },
        {
          name: "teacher",
          path: "/teacher",
          component: Teacher,
        },
        {
          name: "classroom",
          path: "/classroom",
          component: Classroom,
        },
        {
          name: "requiredCourse",
          path: "/requiredCourse",
          component: RequiredCourse,
        },
        {
          name: "electiveCourse",
          path: "/electiveCourse",
          component: ElectiveCourse,
        },
        {
          name: "electiveCourseAdd",
          path: "/electiveCourseAdd",
          component: ElectiveCourseAdd,
        },
        {
          name: "retakeCourse",
          path: "/retakeCourse",
          component: RetakeCourse,
        },
        {
          name: "adjustCourse",
          path: "/adjustCourse",
          component: AdjustCourse,
        },
        {
          name: "teacherAdjustCourse",
          path: "/teacherAdjustCourse",
          component: TeacherAdjustCourse,
        },
        {
          name: "teacherCourse",
          path: "/teacherCourse",
          component: TeacherCourse,
        },
        {
          name: "studentCourse",
          path: "/studentCourse",
          component: StudentCourse,
        },
        {
          name: "studentSchedule",
          path: "/studentSchedule",
          component: StudentSchedule,
        },
        {
          name: "teacherSchedule",
          path: "/teacherSchedule",
          component: TeacherSchedule,
        },
        {
          name: "choice",
          path: "/choice",
          component: Choice,
        },
        {
          name: "studentChoice",
          path: "/studentChoice",
          component: StudentChoice,
        },
        {
          name: "teacherChoice",
          path: "/teacherChoice",
          component: TeacherChoice,
        },
        {
          name: "studentExam",
          path: "/studentExam",
          component: StudnetExam,
        },
        {
          name: "exam",
          path: "/exam",
          component: Exam,
        },
        {
          name: "assignExam",
          path: "/assignExam",
          component: AssignExam,
        },
        {
          name: "teacherExam",
          path: "/teacherExam",
          component: TeacherExam,
        },
        {
          name: "superviseExam",
          path: "/superviseExam",
          component: SuperviseExam,
        },
        {
          name: "studentScore",
          path: "/studentScore",
          component: StudentScore,
        },
        {
          name: "teacherScore",
          path: "/teacherScore",
          component: TeacherScore,
        },
        {
          name: "addScore",
          path: "/addScore",
          component: AddScore,
        },
        {
          name: "score",
          path: "/score",
          component: Score,
        },
        {
          name: "scoreStatus",
          path: "/scoreStatus",
          component: ScoreStatus,
        },
        {
          name: "studentChart",
          path: "/studentChart",
          component: StudentChart,
        },
        {
          name: "teacherChart",
          path: "/teacherChart",
          component: TeacherChart,
        },
        {
          name: "chart",
          path: "/chart",
          component: Chart,
        },
        {
          name: "studentEvaluation",
          path: "/studentEvaluation",
          component: StudentEvaluation,
        },
        {
          name: "evaluation",
          path: "/evaluation",
          component: Evaluation,
        },
        {
          name: "teacherEvaluation",
          path: "/teacherEvaluation",
          component: TeacherEvaluation,
        },
        {
          name: "studentRetake",
          path: "/studentRetake",
          component: StudentRetake,
        },
      ],
    },
    {
      path: "*",
      component: Home,
    },
  ],
});

// 导航守卫限制路由跳转
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token");
  if (token || to.path === "/login") next();
  else next("/login");
});

export default router;
