<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>教学评价</el-breadcrumb-item>
      <el-breadcrumb-item>教师评价</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="teacher"
          filterable
          placeholder="请选择老师"
          clearable
          @clear="loadExamList"
        >
          <el-option
            v-for="item in teacherList"
            :key="item.id"
            :label="item.teacher"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="queryEvaluation"
          :disabled="this.teacher == ''"
          style="margin-left: 20px"
          >搜索评价</el-button
        >
        <el-button
          type="success"
          style="margin-left: 20px"
          v-if="evaluationStatus"
          @click="startEvaluation"
          >开启评价</el-button
        >
        <el-button
          type="danger"
          style="margin-left: 20px"
          v-else
          @click="closeEvaluation"
          >关闭评价</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="evaluationList"
      class="evaluationTable"
      :row-style="{ height: '65.2px' }"
      max-height="439px"
    >
      <el-table-column align="center" type="index" label="序号" width="120">
      </el-table-column>
      <el-table-column align="center" prop="student" label="学生" width="170">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程" width="170">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="170"
      >
      </el-table-column>
      <el-table-column align="center" label="评价" width="250">
        <template slot-scope="scope">
          <el-rate v-model="scope.row.evaluation" show-text disabled> </el-rate>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="comment" label="评语" width="350">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "Evaluation",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      evaluationList: [],
      teacherList: [],
      teacher: "",
      evaluationStatus: true,
    };
  },
  created() {
    this.queryAllEvalutaion();
  },
  methods: {
    async queryAllEvalutaion() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllEvaluation",
      }).then((res) => {
        const { data } = res.data;
        this.evaluationList = data.evaluationList;
        this.teacherList = data.teacherList;
        this.evaluationStatus = data.evaluationStatus;
        this.total = data.count;
      });
    },
    async startEvaluation() {
      this.$axios({
        method: "get",
        url: "/startEvaluation",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.evaluationStatus = data.evaluationStatus;
          this.successMessage(msg);
        }
      });
    },
    async closeEvaluation() {
      this.$axios({
        method: "get",
        url: "/closeEvaluation",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.evaluationStatus = data.evaluationStatus;
          this.successMessage(msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.teacher === "") this.queryAllEvalutaion();
      else this.queryEvaluation();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.teacher === "") this.queryAllEvalutaion();
      else this.queryEvaluation();
    },
    async queryEvaluation() {
      this.pagenum = 1;
      this.$axios({
        method: "get",
        url: "/queryEvaluation",
        params: {
          teacher: this.teacher,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.successMessage(msg);
          this.evaluationList = data.evaluationList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadExamList() {
      this.queryAllEvalutaion();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.evaluationTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
