<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>选修课列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入课程号"
          @clear="loadCourseList"
          clearable
          v-model="id"
          @keydown.enter.native="queryElectiveCourse"
          class="inputSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryElectiveCourse"
          ></el-button>
        </el-input>
        <el-button
          type="success"
          style="margin-left: 20px"
          v-if="choiceStatus"
          @click="startElectiveCourse"
          >开启选课</el-button
        >
        <el-button
          type="danger"
          style="margin-left: 20px"
          v-else
          @click="closeElectiveCourse"
          >关闭选课</el-button
        >
        <el-button
          type="primary"
          @click="queryElectiveCourseExcelData"
          id="addbutton"
          icon="el-icon-download"
          >导出</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="electiveCourseList"
      class="studentTable"
      max-height="439px"
    >
      <el-table-column align="center" type="index" label="序号" width="70">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="80">
      </el-table-column>
      <el-table-column align="center" prop="name" label="课程名" width="120">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="100"
      >
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="100">
      </el-table-column>
      <el-table-column align="center" prop="number" label="容量" width="80">
      </el-table-column>
      <el-table-column
        align="center"
        prop="choiceNumber"
        label="选课人数"
        width="100"
      >
      </el-table-column>
      <el-table-column align="center" prop="zcFrom" label="起(周)" width="70">
      </el-table-column>
      <el-table-column align="center" prop="zcTo" label="止(周)" width="70">
      </el-table-column>
      <el-table-column align="center" prop="week" label="星期" width="80">
      </el-table-column>
      <el-table-column align="center" prop="start" label="上(节)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="end" label="下(节)" width="60">
      </el-table-column>
      <el-table-column label="公开" align="center" width="70">
        <template slot-scope="scope">
          <el-switch
            @change="changeElectiveCourseStatus(scope.row)"
            v-model="scope.row.flag"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="showAlterCourseDia(scope.row)"
            plain
            icon="el-icon-edit"
            circle
          ></el-button>
          <el-button
            type="danger"
            @click="showDeleteCourseMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!--修改用户的对话框-->
    <el-dialog
      title="修改选修课"
      :visible.sync="dialogFormVisibleAlter"
      style="text-align: center; margin-top: -100px; height: 900px"
      width="750px"
    >
      <el-form :model="form" label-width="70px">
        <el-form-item label="课程号">
          <el-input v-model="form.id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="课程名">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="学分">
          <el-input v-model="form.credit" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="容量">
          <el-input v-model="form.number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="任课老师">
          <el-select
            style="width: 100%"
            v-model="form.teacher"
            placeholder="请选择"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.teacher"
              :value="item.teacher"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择开始日期"
              v-model="form.from"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择结结束日期"
              v-model="form.to"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="星期">
          <el-select v-model="form.week" style="width: 100%">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上(节)">
              <el-select v-model="form.start" style="width: 100%">
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="7" value="7"></el-option>
                <el-option label="8" value="8"></el-option>
                <el-option label="9" value="9"></el-option>
                <el-option label="10" value="10"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="下(节)">
              <el-select v-model="form.end" style="width: 100%">
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="7" value="7"></el-option>
                <el-option label="8" value="8"></el-option>
                <el-option label="9" value="9"></el-option>
                <el-option label="10" value="10"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="教室">
          <el-select
            style="width: 100%"
            v-model="form.classroom"
            filterable
            placeholder="请选择教室"
          >
            <el-option
              v-for="item in classroomList"
              :key="item.id"
              :label="item.classroom"
              :value="item.classroom"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -5%">
        <el-button @click="dialogFormVisibleAlter = false">取 消</el-button>
        <el-button type="primary" @click="alterElectiveCourse">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
export default {
  name: "ElectiveCourse",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      choiceStatus: true,
      //表格绑定的数据
      electiveCourseList: [],
      excelData: [],
      teacherList: [],
      classroomList: [],
      //添加对话框属性
      dialogFormVisibleAdd: false,
      dialogFormVisibleAlter: false,
      //添加用户的表单数据
      form: {
        id: "",
        name: "",
        teacher: "",
        week: "",
        start: "",
        end: "",
        from: "",
        to: "",
        credit: "",
        number: "",
      },
      id: "",
      ruleForm: {
        id: "",
        name: "",
        grade: "",
        sex: "",
      },
      rules: {
        id: [{ required: true, message: "请输入学号", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        grade: [{ required: true, message: "请输入班级", trigger: "blur" }],
        sex: { required: true, message: "请选择性别", trigger: "change" },
      },
    };
  },
  created() {
    this.queryAllElectiveCourse();
  },
  methods: {
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        课程号: "id",
        课程名: "name",
        学分: "credit",
        任课老师: "teacher",
        容量: "number",
        教室: "classroom",
        开始时间: "from",
        结束时间: "to",
        "起(周)": "zcFrom",
        "末(周)": "zcTo",
        星期: "week",
        上课节次: "start",
        下课节次: "end",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: "选修课表",
        sheet: "选修课",
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryClassroomByNumber(number) {
      this.$axios({
        method: "get",
        params: {
          number: number,
        },
        url: "/queryClassroomByNumber",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.classroomList = data.classroomList;
        }
      });
    },
    async queryElectiveCourseExcelData() {
      this.$axios({
        method: "get",
        url: "/queryElectiveCourseExcelData",
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.exportExcel();
      });
    },
    async queryAllElectiveCourse() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllElectiveCourse",
      }).then((res) => {
        const { data } = res.data;
        this.electiveCourseList = data.electiveCourseList;
        this.teacherList = data.teacherList;
        this.total = data.count;
        this.choiceStatus = data.choiceStatus;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.id === "") this.queryAllElectiveCourse();
      else this.queryElectiveCourse();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.id === "") this.queryAllElectiveCourse();
      else this.queryElectiveCourse();
    },
    async queryElectiveCourse() {
      if (this.id === "") return;
      this.$axios({
        method: "get",
        url: "/queryElectiveCourse",
        params: {
          id: this.id,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.electiveCourseList = data.electiveCourseList;
          this.total = data.count;
          this.successMessage(msg);
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadCourseList() {
      await this.queryAllElectiveCourse();
    },
    //添加用户表单
    showAddCourseDia() {
      this.form = {};
      this.dialogFormVisibleAdd = true;
    },
    //删除用户盒子
    showDeleteCourseMassage(e) {
      this.$confirm("删除课程?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteElectiveCourse(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    async deleteElectiveCourse(e) {
      this.$axios({
        method: "get",
        url: "/deleteElectiveCourse",
        params: {
          id: e.id,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.queryAllElectiveCourse();
          this.successMessage(msg);
        }
      });
    },
    showAlterCourseDia(course) {
      this.queryClassroomByNumber(course.number);
      this.form = JSON.parse(JSON.stringify(course)); //深拷贝;
      //获取用户数据
      this.dialogFormVisibleAlter = true;
    },
    async changeElectiveCourseStatus(course) {
      this.$axios({
        method: "get",
        url: "/changeElectiveCourseStatus",
        params: {
          course: course.id,
          flag: course.flag,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.queryAllElectiveCourse();
        }
      });
    },
    async alterElectiveCourse() {
      this.$axios({
        method: "post",
        url: "/alterElectiveCourse",
        data: {
          id: this.form.id,
          name: this.form.name,
          teacher: this.form.teacher,
          week: Number(this.form.week),
          start: Number(this.form.start),
          end: Number(this.form.end),
          from: this.form.from,
          to: this.form.to,
          credit: Number(this.form.credit),
          number: Number(this.form.number),
          classroom: this.form.classroom,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.form = {};
          //更新视图
          this.queryAllElectiveCourse();
          this.dialogFormVisibleAlter = false;
        } else this.errorMessage(msg);
      });
    },
    async startElectiveCourse() {
      this.$axios({
        method: "get",
        url: "/startElectiveCourse",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.choiceStatus = data.choiceStatus;
          this.successMessage(msg);
        }
      });
    },
    async closeElectiveCourse() {
      this.$axios({
        method: "get",
        url: "/closeElectiveCourse",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.choiceStatus = data.choiceStatus;
          this.successMessage(msg);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addCourse();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
