<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>选课管理</el-breadcrumb-item>
      <el-breadcrumb-item>选课列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入学生姓名"
          @clear="loadChoiceList"
          clearable
          v-model="student"
          class="inputSearch"
          @keydown.enter.native="queryScore"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryScore"
          ></el-button>
        </el-input>
        <el-button
          type="primary"
          @click="
            dialogExcelFormVisible = true;
            queryAllGradeIdAndName();
          "
          id="addbutton"
          icon="el-icon-download"
          >导出</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="scoreList"
      class="studentTable"
      :row-class-name="tableRowClassName"
      max-height="439px"
    >
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="id" label="学号" width="120">
      </el-table-column>
      <el-table-column align="center" prop="student" label="姓名" width="90">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程号" width="110">
      </el-table-column>
      <el-table-column align="center" prop="name" label="课程名" width="110">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="60">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="110"
      >
      </el-table-column>
      <el-table-column align="center" prop="tag" label="类型" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.kind === 1">必修</el-tag>
          <el-tag v-else-if="scope.row.kind === 2" type="success">选修</el-tag>
          <el-tag v-else-if="scope.row.kind === 3" type="danger">重修</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="usual" label="平时成绩" width="90">
        <template slot-scope="scope">
          {{ scope.row.usual }}({{ scope.row.usualRate }}%)
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="experiment"
        label="实验成绩"
        width="90"
      >
        <template slot-scope="scope">
          {{ scope.row.experiment }}({{ scope.row.experimentRate }}%)
        </template>
      </el-table-column>
      <el-table-column align="center" prop="exam" label="期末成绩" width="90">
        <template slot-scope="scope" style="text-align: center">
          {{ scope.row.experiment }}({{ scope.row.examRate }}%)
        </template>
      </el-table-column>
      <el-table-column align="center" prop="total" label="总分" width="60">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            @click="showDeleteUserMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[30, 50, 80, 100]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      title="导出学生成绩"
      :visible.sync="dialogExcelFormVisible"
      style="text-align: center; width: 70%; margin-left: 17%"
    >
      <el-select
        style="width: 100%"
        v-model="grade"
        filterable
        placeholder="请选择班级"
      >
        <el-option
          v-for="item in gradeList"
          :key="item.id"
          :label="item.grade"
          :value="item.grade"
        >
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExcelFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="queryScoreExcelData">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
export default {
  name: "Score",
  data() {
    return {
      pagenum: 1,
      pagesize: 30,
      total: 0,
      //表格绑定的数据
      scoreList: [],
      dialogExcelFormVisible: false,
      student: "",
      grade: "",
      gradeList: [],
      excelData: [],
    };
  },
  created() {
    this.queryAllScore();
  },
  methods: {
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        班级: "grade",
        学号: "id",
        姓名: "student",
        课程名: "course",
        课程类型: "kind",
        分数: "total",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: `${this.grade}学生成绩表`,
        sheet: this.grade,
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        if (item.kind === 1) item.kind = "必修";
        else if (item.kind === 2) item.kind = "选修";
        else if (item.kind === 3) item.kind = "重修";
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryAllGradeIdAndName() {
      this.$axios({
        method: "get",
        url: "/queryAllGradeIdAndName",
      }).then((res) => {
        const { data } = res.data;
        this.gradeList = data.gradeList;
      });
    },
    async queryScoreExcelData() {
      this.$axios({
        method: "get",
        params: {
          grade: this.grade,
        },
        url: `/queryScoreExcelData/${this.grade}`,
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.dialogExcelFormVisible = false;
        this.exportExcel();
        this.grade = "";
      });
    },
    async queryAllScore() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllScore",
      }).then((res) => {
        const { data } = res.data;
        this.scoreList = data.scoreList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.student === "") this.queryAllScore();
      else this.queryScore();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.student === "") this.queryAllScore();
      else this.queryScore();
    },
    async queryScore() {
      if (this.student === "") reutrn;
      this.pagenum = 1;
      this.$axios({
        method: "get",
        url: "/queryScore",
        params: {
          student: this.student,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.successMessage(msg);
          this.scoreList = data.scoreList;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadChoiceList() {
      this.queryAllScore();
    },
    //删除用户盒子
    showDeleteUserMassage(e) {
      this.$confirm("删除成绩?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteScore(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    tableRowClassName({ row }) {
      if (row.total < 60) {
        return "warning-row";
      }
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
    async deleteScore(e) {
      this.$axios({
        method: "get",
        url: `/deleteScore/${e.id}/${e.course}`,
      }).then((res) => {
        const { msg } = res.data;
        this.successMessage(msg);
        if (this.student === "") this.queryAllScore();
        else this.queryScore();
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.el-table >>> .warning-row {
  color: red;
  /*  font-weight:bold;*/
}
</style>
