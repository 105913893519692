<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>选课管理</el-breadcrumb-item>
      <el-breadcrumb-item>选课列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入学号"
          @clear="loadChoiceList"
          clearable
          v-model="student"
          @keydown.enter.native="queryChoice"
          class="inputSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryChoice"
          ></el-button>
        </el-input>
        <el-button
          type="primary"
          @click="queryChoiceExcelData"
          id="addbutton"
          icon="el-icon-download"
          >导出</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="choiceList" class="studentTable" max-height="439px">
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="student" label="学号" width="140">
      </el-table-column>
      <el-table-column align="center" prop="name" label="学生名" width="140">
      </el-table-column>
      <el-table-column align="center" prop="grade" label="班级" width="140">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="140">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="140">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="140"
      >
      </el-table-column>
      <el-table-column align="center" prop="tag" label="类型" width="140">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.kind === 1">必修</el-tag>
          <el-tag v-else-if="scope.row.kind === 2" type="success">选修</el-tag>
          <el-tag v-else-if="scope.row.kind === 3" type="danger">重修</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            @click="showDeleteUserMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 50, 100, 200]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
export default {
  name: "Choice",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      //表格绑定的数据
      choiceList: [],
      student: "",
      excelData: [],
    };
  },
  created() {
    this.queryAllChoice();
  },
  methods: {
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        课程号: "id",
        课程名: "course",
        学生: "student",
        任课老师: "teacher",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: "选课表",
        sheet: "选课数据",
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryChoiceExcelData() {
      this.$axios({
        method: "get",
        url: "/queryChoiceExcelData",
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.exportExcel();
      });
    },
    async queryAllChoice() {
      this.$axios({
        method: "get",
        params: {
          name: this.name,
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllChoice",
      }).then((res) => {
        const { data } = res.data;
        this.choiceList = data.choiceList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.student === "") this.queryAllChoice();
      else this.queryChoice();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.student === "") this.queryAllChoice();
      else this.queryChoice();
    },
    async queryChoice() {
      if (this.student === "") return;
      this.$axios({
        method: "get",
        url: "/queryChoice",
        params: {
          student: this.student,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.choiceList = data.choiceList;
          this.total = data.count;
          this.successMessage(msg);
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadChoiceList() {
      this.queryAllChoice();
    },
    //删除用户盒子
    showDeleteUserMassage(e) {
      this.$confirm("删除课程?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteChoice(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
    async deleteChoice(e) {
      this.$axios({
        method: "post",
        url: "/deleteChoice",
        data: {
          student: e.student,
          course: e.id,
        },
      }).then((res) => {
        const { msg } = res.data;
        this.successMessage(msg);
        if (this.student === "") this.queryAllChoice();
        else this.queryChoice();
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
</style>
