<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>教师管理</el-breadcrumb-item>
      <el-breadcrumb-item>教师列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入教师工号"
          @clear="loadTeacherList"
          clearable
          v-model="id"
          class="inputSearch"
          @keydown.enter.native="queryTeacher"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryTeacher"
          ></el-button>
        </el-input>
        <el-button type="success" @click="showAddTeacherDia" id="addbutton"
          >添加教师</el-button
        >
        <el-button
          type="primary"
          @click="queryTeacherExcelData"
          id="addbutton"
          icon="el-icon-download"
          >导出</el-button
        >
        <el-upload
          style="display: inline-block; margin-left: 20px"
          action=""
          :on-change="uploadTeacher"
          :auto-upload="false"
          :show-file-list="false"
          accept=".xls, .xlsx"
        >
          <el-button type="primary" icon="el-icon-upload2">导入</el-button>
        </el-upload>
      </el-col>
    </el-row>
    <el-table :data="teacherList" class="studentTable" max-height="439px">
      <el-table-column align="center" type="index" label="序号" width="200">
      </el-table-column>
      <el-table-column align="center" prop="id" label="工号" width="300">
      </el-table-column>
      <el-table-column align="center" prop="name" label="姓名" width="300">
      </el-table-column>
      <el-table-column align="center" prop="sex" label="性别" width="200">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="showAlterTeacherDia(scope.row)"
            plain
            icon="el-icon-edit"
            circle
          ></el-button>
          <el-button
            type="danger"
            @click="showDeleteTeacherMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!--添加学生对话框-->
    <el-dialog
      title="添加教师"
      :visible.sync="dialogFormVisibleAdd"
      center
      width="500px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="工号" prop="id">
          <el-input
            v-model="ruleForm.id"
            style="width: 300px"
            placeholder="请输入工号"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="ruleForm.name"
            style="width: 300px"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select
            v-model="ruleForm.sex"
            placeholder="请选择性别"
            style="width: 300px"
          >
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="resetForm('ruleForm')"
          type="danger"
          circle
          icon="el-icon-delete"
          style="margin-left: 300px !important"
        ></el-button>
        <el-button
          type="success"
          icon="el-icon-check"
          circle
          @click="submitForm('ruleForm')"
        ></el-button>
      </div>
    </el-dialog>
    <!--修改用户的对话框-->
    <el-dialog
      title="修改教师"
      :visible.sync="dialogFormVisibleAlter"
      style="text-align: center"
      width="500px"
    >
      <el-form :model="form" label-width="50px">
        <el-form-item label="工号">
          <el-input v-model="form.id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="form.sex" style="width: 100%">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAlter = false">取 消</el-button>
        <el-button type="primary" @click="alterTeacher">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
import XLSX from "xlsx";
export default {
  name: "Teacher",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      //表格绑定的数据
      teacherList: [],
      //添加对话框属性
      dialogFormVisibleAdd: false,
      dialogFormVisibleAlter: false,
      excelData: [],
      //添加用户的表单数据
      form: {
        id: 0,
        name: "",
        sex: "",
      },
      id: "",
      ruleForm: {
        id: "",
        name: "",
        sex: "",
      },
      rules: {
        id: [{ required: true, message: "请输入学号", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: { required: true, message: "请选择性别", trigger: "change" },
      },
    };
  },
  created() {
    this.queryAllTeacher();
  },
  methods: {
    /* 读取文件 */
    readFile(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
    async uploadTeacher(file) {
      let dataBinary = await this.readFile(file.raw);
      let workBook = XLSX.read(dataBinary, { type: "binary", cellDates: true });
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];
      let data = XLSX.utils.sheet_to_json(workSheet);
      data = data.map((e) => {
        return {
          id: e.工号,
          name: e.教师名,
          sex: e.性别,
        };
      });
      if (data.length === 0) {
        this.errorMessage("表格为空");
        return;
      }
      this.$axios({
        method: "post",
        data: data,
        url: "/uploadTeacher",
      }).then((res) => {
        const { msg } = res.data;
        this.successMessage(msg);
        if (this.id === "") this.queryAllTeacher();
        else this.queryTeacher();
      });
    },
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        工号: "id",
        教师名: "name",
        性别: "sex",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: "教师表",
        sheet: "教师数据",
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryTeacherExcelData() {
      this.$axios({
        method: "get",
        url: "/queryTeacherExcelData",
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.exportExcel();
      });
    },
    async queryAllTeacher() {
      this.$axios({
        method: "get",
        url: "/queryAllTeacher",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { data } = res.data;
        this.teacherList = data.teacherList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.id === "") this.queryAllTeacher();
      else this.queryTeacher();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.id === "") this.queryAllTeacher();
      else this.queryTeacher();
    },
    async queryTeacher() {
      if (this.id === "") return;
      this.$axios({
        method: "get",
        url: "/queryTeacher",
        params: {
          id: this.id,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.teacherList = data.teacherList;
        }
      });
    },
    async loadTeacherList() {
      await this.queryAllTeacher();
    },
    //添加用户表单
    showAddTeacherDia() {
      this.form = {};
      this.dialogFormVisibleAdd = true;
    },
    //添加用户
    async addTeacher() {
      this.$axios({
        method: "post",
        url: "/addTeacher",
        data: {
          id: this.ruleForm.id,
          name: this.ruleForm.name,
          sex: this.ruleForm.sex,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          //更新视图
          this.queryAllTeacher();
          this.resetForm("ruleForm");
          this.dialogFormVisibleAdd = false;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    //删除用户盒子
    showDeleteTeacherMassage(e) {
      this.$confirm("删除用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.total === 1) this.pagenum = 1;
          else if ((this.pagenum - 1) * this.pagesize + 1 === this.total)
            this.pagenum--;
          this.deleteTeacher(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    async deleteTeacher(e) {
      this.$axios({
        method: "get",
        url: "/deleteTeacher",
        params: {
          id: e.id,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          if (this.id != "") this.queryTeacher();
          else this.queryAllTeacher();
          this.successMessage(msg);
        }
      });
    },
    showAlterTeacherDia(teacher) {
      this.form = JSON.parse(JSON.stringify(teacher)); //深拷贝
      //获取用户数据
      this.dialogFormVisibleAlter = true;
    },
    async alterTeacher() {
      this.$axios({
        method: "post",
        url: "/alterTeacher",
        data: {
          id: this.form.id,
          name: this.form.name,
          grade: this.form.grade,
          sex: this.form.sex,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.form = {};
          //更新视图
          if (this.id != "") this.queryTeacher();
          else this.queryAllTeacher();
          this.dialogFormVisibleAlter = false;
        } else this.errorMessage(msg);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addTeacher();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
  padding: 0px;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
