import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueRouter from "vue-router";
import router from "./router";
import axios from "./request/http"
import "@/assets/basic.css";
// 引入echarts
import * as echarts from "echarts";
import AFTableColumn from "af-table-column";
Vue.prototype.$echarts = echarts;
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(AFTableColumn);
Vue.config.productionTip = false;
//全局挂载axios
Vue.prototype.$axios = axios;

// 防止localStorage被修改
window.addEventListener("storage", function (e) {
  localStorage.setItem(e.key, e.oldValue);
});
// 防止sessionStorage被修改
window.addEventListener("storage", function (e) {
  this.sessionStorage.setItem(e.key, e.oldValue);
});

new Vue({
  render: (h) => h(App),
  router: router,
  axios,
}).$mount("#app");
