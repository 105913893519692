<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>成绩管理</el-breadcrumb-item>
      <el-breadcrumb-item>成绩列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="course"
          placeholder="请选择课程"
          clearable
          filterable
          @clear="loadStudentScoreList"
        >
          <el-option
            v-for="item in courseList"
            :key="item.id"
            :label="item.course"
            :value="item.course"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="searchStudentScore"
          :disabled="this.course === ''"
          style="margin-left: 20px"
          >查询成绩</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="studentScoreList"
      class="examTable"
      :row-class-name="tableRowClassName"
      :row-style="{ height: '65.2px' }"
      max-height="439px"
      @sort-change="handleSort"
    >
      <el-table-column align="center" type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="200">
      </el-table-column>
      <el-table-column
        align="center"
        prop="credit"
        label="学分"
        width="160"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column align="center" prop="tag" label="类型" width="190">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.kind === 1">必修</el-tag>
          <el-tag v-else-if="scope.row.kind === 2" type="success">选修</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="250"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="total"
        label="成绩"
        width="160"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column align="center" prop="gpa" label="绩点" width="160">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "StudentScore",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      course: "",
      //表格绑定的数据
      studentScoreList: [],
      courseList: [],
      order: null,
      prop: null,
      //添加对话框属性
      dialogFormVisibleAdd: false,
      dialogFormVisibleAlter: false,
    };
  },
  created() {
    this.getStudentScoreList();
  },
  methods: {
    handleSort({ prop, order }) {
      if (order === "ascending") {
        this.order = "asc";
        this.prop = prop;
      } else if (order === "descending") {
        this.order = "desc";
        this.prop = prop;
      } else {
        this.order = null;
        this.prop = null;
      }
      this.getStudentScoreList();
    },
    async getStudentScoreList() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
          prop: this.prop,
          order: this.order,
        },
        url: "/studentScoreList",
      }).then((res) => {
        const { data } = res.data;
        this.studentScoreList = data.studentScoreList;
        this.courseList = data.courseList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "") this.getStudentScoreList();
      else this.searchStudentScore();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "") this.getStudentScoreList();
      else this.searchStudentScore();
    },
    async searchStudentScore() {
      this.$axios({
        method: "get",
        url: "/searchStudentScore",
        params: {
          course: this.course,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.studentScoreList = data.studentScoreList;
          this.total = data.count;
          this.successMessage(msg);
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadStudentScoreList() {
      await this.getStudentScoreList();
    },
    tableRowClassName({ row }) {
      if (row.total < 60) {
        return "warning-row";
      }
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.examTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}

.el-table >>> .warning-row {
  color: red;
  /*  font-weight:bold;*/
}
</style>
