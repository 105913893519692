<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>考试管理</el-breadcrumb-item>
      <el-breadcrumb-item>考试列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="course"
          placeholder="请选择课程"
          clearable
          filterable
          @clear="loadStudentExamList"
        >
          <el-option
            v-for="item in courseList"
            :key="item.id"
            :label="item.course"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="queryTeacherExam"
          :disabled="this.course == ''"
          style="margin-left: 20px"
          >搜索考试</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="teacherExamList"
      class="examTable"
      :row-style="{ height: '65.2px' }"
      max-height="439px"
    >
      <el-table-column align="center" type="index" label="序号" width="150">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="150">
      </el-table-column>
      <el-table-column align="center" prop="grade" label="班级" width="150">
      </el-table-column>
      <el-table-column
        align="center"
        prop="supervisor"
        label="监考老师"
        width="150"
      >
      </el-table-column>
      <el-table-column align="center" prop="date" label="日期" width="150">
      </el-table-column>
      <el-table-column align="center" prop="start" label="开始时间" width="150">
      </el-table-column>
      <el-table-column align="center" prop="end" label="结束时间" width="150">
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="150">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "TeacherExam",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      course: "",
      //表格绑定的数据
      teacherExamList: [],
      courseList: [],
    };
  },
  created() {
    this.queryAllTeacherExam();
  },
  methods: {
    async queryAllTeacherExam() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllTeacherExam",
      }).then((res) => {
        const { data } = res.data;
        this.teacherExamList = data.teacherExamList;
        this.courseList = data.courseList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "") this.queryAllTeacherExam();
      else this.queryTeacherExam();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "") this.queryAllTeacherExam();
      else this.queryTeacherExam();
    },
    async queryTeacherExam() {
      this.pagenum = 1;
      this.$axios({
        method: "get",
        url: "/queryTeacherExam",
        params: {
          course: this.course,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.teacherExamList = data.teacherExamList;
          this.total = data.count;
          this.successMessage(msg);
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadStudentExamList() {
      await this.queryAllTeacherExam();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.examTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
