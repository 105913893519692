<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>班级管理</el-breadcrumb-item>
      <el-breadcrumb-item>班级列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入班级编号"
          @clear="loadGradeList"
          clearable
          v-model="id"
          class="inputSearch"
          @keydown.enter.native="queryGrade"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryGrade"
          ></el-button>
        </el-input>
        <el-button type="success" @click="showAddGradeDia" id="addbutton"
          >添加班级</el-button
        >
        <el-button
          type="danger"
          @click="showAddRequiredrDia"
          :disabled="this.multipleSelection.length == 0"
          style="margin-left: 20px"
          >添加必修课</el-button
        >
        <el-button
          type="primary"
          @click="queryGradeExcelData"
          id="addbutton"
          icon="el-icon-download"
          >导出</el-button
        >
        <el-upload
          style="display: inline-block; margin-left: 20px"
          action=""
          :on-change="uploadGrade"
          :auto-upload="false"
          :show-file-list="false"
          accept=".xls, .xlsx"
        >
          <el-button type="primary" icon="el-icon-upload2">导入</el-button>
        </el-upload>
      </el-col>
    </el-row>
    <el-table
      :data="gradeList"
      class="studentTable"
      ref="multipleTable"
      tooltip-effect="dark"
      style="width: 100%"
      :row-key="getRowKeys"
      height="calc(100vh -10px)"
      @selection-change="handleSelectionChange"
      @row-click="handleSelection"
    >
      <el-table-column
        align="center"
        type="selection"
        min-width="10%"
        :reserve-selection="true"
      >
      </el-table-column>
      <el-table-column align="center" type="index" label="序号" min-width="10%">
      </el-table-column>
      <el-table-column align="center" prop="id" label="班级号" min-width="10%">
      </el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="班级名"
        min-width="10%"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="number"
        label="人数"
        min-width="10%"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="10%">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click.native.stop="showAlterGradeDia(scope.row)"
            plain
            icon="el-icon-edit"
            circle
          ></el-button>
          <el-button
            type="danger"
            @click.native.stop="showDeleteGradetMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!--添加学生对话框-->
    <el-dialog
      title="添加班级"
      :visible.sync="dialogFormVisibleAdd"
      center
      width="500px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="班级号" prop="id">
          <el-input
            v-model="ruleForm.id"
            style="width: 100%"
            placeholder="请输入班级号"
          ></el-input>
        </el-form-item>
        <el-form-item label="班级名" prop="name">
          <el-input
            v-model="ruleForm.name"
            style="width: 100%"
            placeholder="请输入班级名"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="resetForm('ruleForm')"
          type="danger"
          circle
          icon="el-icon-delete"
          style="margin-left: 300px !important"
        ></el-button>
        <el-button
          type="success"
          icon="el-icon-check"
          circle
          @click="submitForm('ruleForm')"
        ></el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改班级"
      :visible.sync="dialogFormVisibleAlter"
      style="text-align: center"
      width="500px"
    >
      <el-form :model="form" label-width="70px">
        <el-form-item label="班级号">
          <el-input v-model="form.id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="班级名">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAlter = false">取 消</el-button>
        <el-button type="primary" @click="alterGrade">确 定</el-button>
      </div>
    </el-dialog>
    <!--添加必修课-->
    <el-dialog
      title="添加必修课"
      :visible.sync="dialogCourseFormVisibleAdd"
      style="text-align: center; margin-top: -90px; height: 900px"
    >
      <el-form :model="courseForm">
        <el-form-item label="课程号" label-width="100px">
          <el-input
            v-model="courseForm.id"
            autocomplete="off"
            placeholder="请输入课程号"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程名" label-width="100px">
          <el-input
            v-model="courseForm.course"
            autocomplete="off"
            placeholder="请输入课程名"
          ></el-input>
        </el-form-item>
        <el-form-item label="学分" label-width="100px">
          <el-input
            v-model="courseForm.credit"
            autocomplete="off"
            placeholder="请输入学分"
          ></el-input>
        </el-form-item>
        <el-form-item label="老师" label-width="100px">
          <el-select
            style="width: 100%"
            v-model="courseForm.teacher"
            filterable
            placeholder="请选择老师"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.teacher"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间" label-width="100px">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择开始日期"
              v-model="courseForm.from"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择结结束日期"
              v-model="courseForm.to"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="星期" label-width="100px">
          <el-select v-model="courseForm.week" style="width: 100%">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课节次" label-width="100px">
          <el-select v-model="courseForm.start" style="width: 100%">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
            <el-option label="8" value="8"></el-option>
            <el-option label="9" value="9"></el-option>
            <el-option label="10" value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下课节次" label-width="100px">
          <el-select v-model="courseForm.end" style="width: 100%">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
            <el-option label="8" value="8"></el-option>
            <el-option label="9" value="9"></el-option>
            <el-option label="10" value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教室" label-width="100px">
          <el-select
            style="width: 100%"
            v-model="courseForm.classroom"
            filterable
            placeholder="请选择教室"
          >
            <el-option
              v-for="item in classroomList"
              :key="item.id"
              :label="item.classroom"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -50px">
        <el-button
          @click="
            dialogCourseFormVisibleAdd = false;
            this.requiredGradeList = [];
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addRequiredCourse">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
import XLSX from "xlsx";
export default {
  name: "Grade",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      //表格绑定的数据
      gradeList: [],
      //添加对话框属性
      dialogFormVisibleAdd: false,
      dialogCourseFormVisibleAdd: false,
      dialogFormVisibleAlter: false,
      //添加用户的表单数据
      form: {
        id: "",
        name: "",
      },
      id: "",
      excelData: [],
      teacherList: [],
      courseForm: {
        id: "",
        course: "",
        teacher: "",
        week: "",
        start: "",
        end: "",
        classroom: "",
        from: "",
        to: "",
        credit: "",
      },
      number: 0,
      ruleForm: {
        id: "",
        name: "",
      },
      rules: {
        id: [{ required: true, message: "请输入班级号", trigger: "blur" }],
        name: [{ required: true, message: "请输入班级名", trigger: "blur" }],
      },
      multipleSelection: [],
      requiredGradeList: [],
      classroomList: [],
    };
  },
  created() {
    this.queryAllGrade();
  },
  methods: {
    /* 读取文件 */
    readFile(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
    async uploadGrade(file) {
      let dataBinary = await this.readFile(file.raw);
      let workBook = XLSX.read(dataBinary, { type: "binary", cellDates: true });
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];
      let data = XLSX.utils.sheet_to_json(workSheet);
      data = data.map((e) => {
        return {
          id: e.班级号,
          name: e.班级名,
        };
      });
      if (data.length === 0) {
        this.errorMessage("表格为空");
        return;
      }
      this.$axios({
        method: "post",
        data: data,
        url: "/uploadGrade",
      }).then((res) => {
        const { msg } = res.data;
        this.successMessage(msg);
        if (this.id === "") this.queryAllGrade();
        else this.queryGrade();
      });
    },
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        班级号: "id",
        班级名: "name",
        人数: "number",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: "班级表",
        sheet: "班级数据",
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryGradeExcelData() {
      this.$axios({
        method: "get",
        url: "/queryGradeExcelData",
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.exportExcel();
      });
    },
    getRowKeys(row) {
      return row.id;
    },
    handleSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    async queryClassroomByNumber() {
      this.multipleSelection.forEach((item) => {
        this.number += item.number;
      });
      this.$axios({
        method: "get",
        params: {
          number: this.number,
        },
        url: "/queryClassroomByNumber",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.classroomList = data.classroomList;
          this.requiredGradeList = [];
          this.number = 0;
        }
      });
    },
    async addRequiredCourse() {
      this.multipleSelection.forEach((item) => {
        this.requiredGradeList.push(item.id);
        this.number += item.number;
      });
      this.multipleSelection = [];
      this.$axios({
        method: "post",
        data: {
          requiredGradeList: this.requiredGradeList,
          id: this.courseForm.id,
          course: this.courseForm.course,
          teacher: this.courseForm.teacher,
          week: Number(this.courseForm.week),
          start: Number(this.courseForm.start),
          end: Number(this.courseForm.end),
          classroom: this.courseForm.classroom,
          number: this.number,
          from: this.courseForm.from,
          to: this.courseForm.to,
          credit: Number(this.courseForm.credit),
        },
        url: "/addRequiredCourse",
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.requiredGradeList = [];
          this.courseForm = {};
          this.successMessage(msg);
          this.dialogCourseFormVisibleAdd = false;
          this.toggleSelection();
          this.number = 0;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async queryAllGrade() {
      this.$axios({
        method: "get",
        params: {
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllGrade",
      }).then((res) => {
        const { data } = res.data;
        this.gradeList = data.gradeList;
        this.teacherList = data.teacherList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.id === "") this.queryAllGrade();
      else this.queryGrade();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.id === "") this.queryAllGrade();
      else this.queryGrade();
    },
    async queryGrade() {
      if (this.id === "") return;
      this.$axios({
        method: "get",
        url: "/queryGrade",
        params: {
          id: this.id,
        },
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.gradeList = data.gradeList;
        }
      });
    },
    async loadGradeList() {
      await this.queryAllGrade();
    },
    //添加用户表单
    showAddGradeDia() {
      this.dialogFormVisibleAdd = true;
    },
    showAddRequiredrDia() {
      this.dialogCourseFormVisibleAdd = true;
      this.queryClassroomByNumber();
    },
    //添加用户
    async addGrade() {
      this.$axios({
        method: "post",
        url: "/addGrade",
        data: {
          id: this.ruleForm.id,
          name: this.ruleForm.name,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          //更新视图
          this.queryAllGrade();
          this.resetForm("ruleForm");
          this.dialogFormVisibleAdd = false;
        } else {
          this.errorMessage(msg);
        }
      });
    },
    //删除用户盒子
    showDeleteGradetMassage(e) {
      this.$confirm("删除该班级?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.total === 1) this.pagenum = 1;
          else if ((this.pagenum - 1) * this.pagesize + 1 === this.total)
            this.pagenum--;
          this.deleteGrade(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    async deleteGrade(e) {
      this.$axios({
        method: "get",
        url: "/deleteGrade",
        params: {
          id: e.id,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          if (this.id != "") this.queryGrade();
          else this.queryAllGrade();
          this.successMessage(msg);
        }
      });
    },
    showAlterGradeDia(student) {
      this.form = student;
      //获取用户数据
      this.dialogFormVisibleAlter = true;
    },
    async alterGrade() {
      this.$axios({
        method: "post",
        url: "/alterGrade",
        data: {
          id: this.form.id,
          name: this.form.name,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          //更新视图
          if (this.id != "") this.queryGrade();
          else this.queryAllGrade();
          this.dialogFormVisibleAlter = false;
        } else this.errorMessage(msg);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addGrade();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
  box-sizing: border-box;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
</style>
