<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>选课管理</el-breadcrumb-item>
      <el-breadcrumb-item>选课列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="course"
          filterable
          placeholder="请选择课程"
          clearable
          @clear="loadTeacherChoiceList"
        >
          <el-option
            v-for="item in teacherCourseList"
            :key="item.course"
            :label="item.course"
            :value="item.course"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="queryTeacherChoice"
          :disabled="this.course == ''"
          style="margin-left: 20px"
          >搜索课程</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="teacherChoiceList" class="studentTable" max-height="439px">
      <el-table-column align="center" type="index" label="序号" width="150">
      </el-table-column>
      <el-table-column align="center" prop="student" label="学号" width="160">
      </el-table-column>
      <el-table-column align="center" prop="name" label="学生" width="160">
      </el-table-column>
      <el-table-column align="center" prop="grade" label="学生" width="160">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="160">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="160">
      </el-table-column>
      <el-table-column align="center" prop="tag" label="类型" width="140">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.kind === 1">必修</el-tag>
          <el-tag v-else-if="scope.row.kind === 2" type="success">选修</el-tag>
          <el-tag v-else-if="scope.row.kind === 3" type="danger">重修</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            @click="showDeleteUserMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: "TeacherChoice",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      //表格绑定的数据
      teacherChoiceList: [],
      teacherCourseList: [],
      course: "",
    };
  },
  created() {
    this.queryAllTeacherChoice();
  },
  methods: {
    async queryAllTeacherChoice() {
      this.$axios({
        method: "get",
        params: {
          name: this.name,
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllTeacherChoice",
      }).then((res) => {
        const { data } = res.data;
        this.teacherChoiceList = data.teacherChoiceList;
        this.teacherCourseList = data.teacherCourseList;
        this.total = data.count;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "") this.queryAllTeacherChoice();
      else this.queryTeacherChoice();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "") this.queryAllTeacherChoice();
      else this.queryTeacherChoice();
    },
    async queryTeacherChoice() {
      this.$axios({
        method: "get",
        url: "/queryTeacherChoice",
        params: {
          course: this.course,
          num: this.pagenum,
          size: this.pagesize,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.total = data.count;
          this.teacherChoiceList = data.teacherChoiceList;
          this.successMessage(msg);
        } else {
          this.errorMessage(msg);
        }
      });
    },
    async loadTeacherChoiceList() {
      this.queryAllTeacherChoice();
    },
    //删除用户盒子
    showDeleteUserMassage(e) {
      this.$confirm("删除课程?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteTeacherChoice(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    async deleteTeacherChoice(e) {
      this.$axios({
        method: "post",
        url: "/deleteTeacherChoice",
        data: {
          student: e.student,
          course: e.id,
        },
      }).then((res) => {
        const { msg } = res.data;
        this.successMessage(msg);
        if (this.course === "") this.queryAllTeacherChoice();
        else this.queryTeacherChoice();
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
</style>
