<template>
  <el-card v-if="evaluationStatus" class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>教学评价</el-breadcrumb-item>
      <el-breadcrumb-item>教师评价</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-select
          v-model="course"
          filterable
          placeholder="请选择课程"
          clearable
          @clear="searchStudentEvaluation"
          @change="queryChoiceTeacherByCourse"
        >
          <el-option
            v-for="item in courseList"
            :key="item.id"
            :label="item.course"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          style="margin-left: 20px"
          v-model="teacher"
          placeholder="请选择老师"
          clearable
          filterable
          @clear="searchStudentEvaluation"
          @change="queryChoiceCourseByTeacher"
        >
          <el-option
            v-for="item in teacherList"
            :key="item.id"
            :label="item.teacher"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="searchStudentEvaluation"
          :disabled="course === '' && teacher === ''"
          style="margin-left: 20px"
          >搜索评价</el-button
        >
        <el-button
          type="success"
          @click="addEvaluation"
          style="margin-left: 20px"
          >提交评价</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="evaluationList" class="examTable" max-height="486px">
      <el-table-column align="center" type="index" label="序号" width="150">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程" width="150">
      </el-table-column>
      <el-table-column align="center" prop="teacher" label="老师" width="200">
      </el-table-column>
      <el-table-column align="center" label="评价" width="200">
        <template slot-scope="scope">
          <el-rate v-model="scope.row.evaluation" show-text> </el-rate>
        </template>
      </el-table-column>
      <el-table-column align="center" label="评语" width="520">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.comment"
            placeholder="请输入评语"
            class="input"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "StudentEvaluation",
  data() {
    return {
      course: "",
      teacher: "",
      //表格绑定的数据
      teacherList: [],
      courseList: [],
      evaluationList: [],
      evaluationStatus: false,
    };
  },
  created() {
    this.queryStudentEvaluationList();
  },
  methods: {
    async queryStudentEvaluationList() {
      this.$axios({
        method: "get",
        url: "/queryStudentEvaluationList",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.evaluationStatus = true;
          this.evaluationList = data.studentEvaluationList;
          this.courseList = data.courseList;
          this.teacherList = data.teacherList;
        } else {
          this.evaluationStatus = false;
          this.errorMessage(msg);
        }
      });
    },
    async queryChoiceTeacherByCourse() {
      this.$axios({
        method: "get",
        params: {
          course: this.course,
        },
        url: "/queryChoiceTeacherByCourse",
      }).then((res) => {
        const { data } = res.data;
        this.teacherList = data.teacherList;
      });
    },
    async queryChoiceCourseByTeacher() {
      this.$axios({
        method: "get",
        params: {
          teacher: this.teacher,
        },
        url: "/queryChoiceCourseByTeacher",
      }).then((res) => {
        const { data } = res.data;
        this.courseList = data.courseList;
      });
    },
    async searchStudentEvaluation() {
      this.$axios({
        method: "get",
        url: "/searchStudentEvaluation",
        params: {
          course: this.course,
          teacher: this.teacher,
        },
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.evaluationList = data.evaluationList;
        } else {
          this.errorMessage(msg);
          this.evaluationStatus = false;
        }
      });
    },
    async loadExamList() {
      await this.queryStudentEvaluationList();
    },
    //添加评价
    async addEvaluation() {
      this.$axios({
        method: "post",
        url: "/addEvaluation",
        data: {
          studentEvaluationList: this.evaluationList,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          //更新视图
          this.queryStudentEvaluationList();
        } else {
          this.evaluationStatus = false;
          this.errorMessage(msg);
          this.evaluationStatus = false;
        }
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.examTable {
  width: 100%;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
::v-deep .input .el-input__inner {
  width: 100%;
  text-align: center;
  width: 400px;
  height: 38px;
}
</style>
