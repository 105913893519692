<template>
  <div>
    <el-card style="height: 60%">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>数据图</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row class="searchRow">
        <el-col>
          <el-select
            v-model="course"
            filterable
            placeholder="请选择课程"
            clearable
            @change="queryTeacherChoiceGradeByCourse"
  
          >
            <el-option
              v-for="item in courseList"
              :key="item.id"
              :label="item.course"
              :value="item.course"
            >
            </el-option>
          </el-select>
          <el-select
            style="margin-left: 20px"
            v-model="grade"
            placeholder="请选择班级"
            clearable
            filterable
            @change="queryChoiceCourseByGrade"
          >
            <el-option
              v-for="item in gradeList"
              :key="item.id"
              :label="item.grade"
              :value="item.grade"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            @click="
              initCharts();
              initDataCharts();
            "
            style="margin-left: 20px"
            >展示图表</el-button
          >
        </el-col>
      </el-row>
      <el-col :span="13">
        <div id="echart" style="height: 495px; margin-top: 20px"></div>
      </el-col>
      <el-col :span="11">
        <div
          id="echart1"
          style="height: 495px; margin-top: 20px; margin-left: -50px"
        ></div>
      </el-col>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "TeacherChart",
  data() {
    return {
      course: "",
      grade: "",
      courseList: [],
      gradeList: [],
      myChart: null,
      dataChart: null,
    };
  },
  mounted() {
    this.initCharts();
    this.initDataCharts();
  },
  methods: {
    async initCharts() {
      if (
        //判断是否存在echarts实例化对象,如果存在则销毁
        this.myChart != null &&
        this.myChart != "" &&
        this.myChart != undefined
      ) {
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("echart"));
      // 绘制图表
      let option1;
      await this.$axios({
        method: "get",
        url: "/queryTeacherChart",
        params: {
          course: this.course,
          grade: this.grade,
        },
      }).then((res) => {
        const { msg, data } = res.data;
        this.successMessage(msg);
        option1 = data.teacherChartList;
        this.courseList = data.courseList;
        this.gradeList = data.gradeList;
      });
      const option = {
        title: {
          text: "成绩分布图",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        color: ["#ee6666", "#91cc75", "#fc8452", "#73c0de", "#ea7ccc"],
        series: [
          {
            type: "pie",
            data: option1,
            label: {
              show: true, // 默认 false
              formatter: "{b}({d}%)", //自定义显示格式(b:name, c:value, d:百分比)
            },
            stillShowZeroSum: false,
          },
        ],
      };
      this.myChart.clear(); //清空画布数据
      this.myChart.setOption(option);
    },
    async initDataCharts() {
      if (
        //判断是否存在echarts实例化对象,如果存在则销毁
        this.dataChart != null &&
        this.dataChart != "" &&
        this.dataChart != undefined
      ) {
        this.dataChart.dispose();
      }
      this.dataChart = this.$echarts.init(document.getElementById("echart1"));
      // 绘制图表
      let option1;
      let option2;
      await this.$axios({
        method: "get",
        url: "/queryTeacherDataChart",
        params: {
          course: this.course,
          grade: this.grade,
        },
      }).then((res) => {
        const { data } = res.data;
        option1 = data.dataChartX;
        option2 = data.dataChartY;
      });
      const option = {
        title: {
          text: "成绩分析图", // 主标题
          left: "center",
          // 主标题文本样式设置
          textStyle: {
            fontWeight: "bold",
            fontSize: "20px",
          },
        },
        tooltip: {},
        legend: {
          show: true,
          bottom: "2%",
        },
        xAxis: {
          data: option1,
          name: "类型",
        },
        yAxis: {
          //设置轴线
          axisLine: {
            show: true,
          },
          name: "数值",
        },
        series: [
          {
            name: "数据",
            type: "bar",
            data: option2,
            itemStyle: {
              borderRadius: 5,
              color: function (params) {
                // 给出颜色组
                var colorList = [
                  "#F5E508",
                  "#AE6173",
                  "#d7a02b",
                  "#23DE80",
                  "#F50820",
                  "#085BF5",
                ];
                //循环调用
                return colorList[params.dataIndex % colorList.length];
              },
            },
          },
        ],
      };
      this.dataChart.clear(); //清空画布数据
      this.dataChart.setOption(option);
    },
    async queryTeacherChoiceGradeByCourse() {
      this.$axios({
        method: "get",
        params: {
          course: this.course,
        },
        url: "/queryTeacherChoiceGradeByCourse",
      }).then((res) => {
        const { data } = res.data;
        this.gradeList = data.gradeList;
      });
    },
    async queryChoiceCourseByGrade() {
      this.$axios({
        method: "get",
        params: {
          grade: this.grade,
        },
        url: "/queryChoiceCourseByGrade",
      }).then((res) => {
        const { data } = res.data;
        this.courseList = data.courseList;
      });
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  text-align: center;
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
