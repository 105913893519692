<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>重修课列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入课程名"
          @clear="loadRetakeCourseList"
          clearable
          v-model="name"
          @keydown.enter.native="queryRetakeCourse"
          class="inputSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryRetakeCourse"
          ></el-button>
        </el-input>
        <el-button
          type="success"
          style="margin-left: 20px"
          v-if="retakeStatus"
          @click="startRetakeCourse"
          >开启重修</el-button
        >
        <el-button
          type="danger"
          style="margin-left: 20px"
          v-else
          @click="closeRetakeCourse"
          >关闭重修</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="retakeCourseList" class="studentTable" max-height="439px">
      <el-table-column type="index" label="序号" align="center" width="50">
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="100">
      </el-table-column>
      <el-table-column align="center" prop="name" label="课程名" width="100">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="120"
      >
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="120">
      </el-table-column>
      <el-table-column align="center" prop="number" label="容量" width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="choiceNumber"
        label="选课人数"
        width="100"
      >
      </el-table-column>
      <el-table-column align="center" prop="zcFrom" label="起(周)" width="80">
      </el-table-column>
      <el-table-column align="center" prop="zcTo" label="止(周)" width="80">
      </el-table-column>
      <el-table-column align="center" prop="week" label="周次" width="80">
      </el-table-column>
      <el-table-column align="center" prop="start" label="上(节)" width="80">
      </el-table-column>
      <el-table-column align="center" prop="end" label="下(节)" width="80">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="showAlterRetakeCourseDia(scope.row)"
            plain
            icon="el-icon-edit"
            circle
          ></el-button>
          <el-button
            type="danger"
            @click="showDeleteRetakeCoursetMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!--修改重修课-->
    <el-dialog
      title="修改重修课"
      :visible.sync="dialogCourseFormVisibleAlter"
      style="text-align: center; margin-top: -6%; height: 900px"
      width="750px"
    >
      <el-form :model="form" label-width="70px">
        <el-form-item label="课程号">
          <el-input
            v-model="form.id"
            autocomplete="off"
            disabled
            placeholder="请输入课程号"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程名">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入课程名"
          ></el-input>
        </el-form-item>
        <el-form-item label="学分">
          <el-input v-model="form.credit" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="容量">
          <el-input v-model="form.number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="老师">
          <el-select
            style="width: 100%"
            v-model="form.teacher"
            filterable
            placeholder="请选择老师"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.teacher"
              :value="item.teacher"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择开始日期"
              v-model="form.from"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择结结束日期"
              v-model="form.to"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="星期">
          <el-select v-model="form.week" style="width: 100%">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上(节)">
              <el-select v-model="form.start" style="width: 100%">
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="7" value="7"></el-option>
                <el-option label="8" value="8"></el-option>
                <el-option label="9" value="9"></el-option>
                <el-option label="10" value="10"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="下(节)">
              <el-select v-model="form.end" style="width: 100%">
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="7" value="7"></el-option>
                <el-option label="8" value="8"></el-option>
                <el-option label="9" value="9"></el-option>
                <el-option label="10" value="10"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="教室">
          <el-select
            style="width: 100%"
            v-model="form.classroom"
            filterable
            placeholder="请选择教室"
          >
            <el-option
              v-for="item in classroomList"
              :key="item.id"
              :label="item.classroom"
              :value="item.classroom"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -5%">
        <el-button @click="dialogCourseFormVisibleAlter = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="updateRetakeCourse">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "RetakeCourse",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      retakeCourseList: [],
      retakeStatus: true,
      teacherList: [],
      classroomList: [],
      name: "",
      form: {
        id: "",
        name: "",
        teacher: "",
        week: "",
        start: "",
        end: "",
        classroom: "",
        number: "",
        credit: "",
      },
      dialogCourseFormVisibleAlter: false,
    };
  },
  created() {
    this.queryAllRetakeCourse();
  },
  methods: {
    async queryClassroomByNumber(number) {
      this.$axios({
        method: "get",
        params: {
          number: number,
        },
        url: "/queryClassroomByNumber",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.classroomList = data.classroomList;
        }
      });
    },
    async queryAllTeacherIdAndName() {
      this.$axios({
        method: "get",
        url: "/queryAllTeacherIdAndName",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.teacherList = data.teacherList;
        }
      });
    },
    async queryAllRetakeCourse() {
      this.$axios({
        method: "get",
        params: {
          course: this.course,
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllRetakeCourse",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.retakeCourseList = data.retakeCourseList;
          this.total = data.count;
          this.retakeStatus = data.retakeStatus;
        }
      });
    },
    async queryRetakeCourse() {
      if (this.course === "") return;
      this.$axios({
        method: "get",
        params: {
          name: this.name,
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryRetakeCourse",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.retakeCourseList = data.retakeCourseList;
          this.total = data.count;
        }
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.course === "") this.queryAllRetakeCourse();
      else this.queryRetakeCourse();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.course === "") this.queryAllRetakeCourse();
      else this.queryRetakeCourse();
    },
    showAlterRetakeCourseDia(course) {
      this.form = JSON.parse(JSON.stringify(course)); //深拷贝
      this.queryClassroomByNumber(course.number);
      this.queryAllTeacherIdAndName();
      this.dialogCourseFormVisibleAlter = true;
    },
    async updateRetakeCourse() {
      this.$axios({
        method: "post",
        url: "/updateRetakeCourse",
        data: {
          id: this.form.id,
          name: this.form.name,
          teacher: this.form.teacher,
          week: Number(this.form.week),
          start: Number(this.form.start),
          end: Number(this.form.end),
          classroom: this.form.classroom,
          from: this.form.from,
          to: this.form.to,
          number: Number(this.form.number),
          credit: Number(this.form.credit),
          classroom: this.form.classroom,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.dialogCourseFormVisibleAlter = false;
          if (this.name != "") this.queryRetakeCourse();
          else this.queryAllRetakeCourse();
        }
      });
    },
    async loadRetakeCourseList() {
      await this.queryAllRetakeCourse();
    },
    //删除用户盒子
    showDeleteRetakeCoursetMassage(e) {
      this.$confirm("删除该重修课?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.total === 1) this.pagenum = 1;
          else if ((this.pagenum - 1) * this.pagesize + 1 === this.total)
            this.pagenum--;
          this.deleteRetakeCourse(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    async deleteRetakeCourse(e) {
      this.$axios({
        method: "get",
        url: "/deleteRetakeCourse",
        params: {
          course: e.id,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          if (this.name != "") this.queryRetakeCourse();
          else this.queryAllRetakeCourse();
          this.successMessage(msg);
        }
      });
    },
    async deleteRetakeCourseChildren(e) {
      this.$axios({
        method: "get",
        url: "/deleteRetakeCourseChildren",
        params: {
          course: e.id,
          course: e.course,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          if (this.course != "") this.queryRetakeCourse();
          else this.queryAllRetakeCourse();
          this.successMessage(msg);
        }
      });
    },
    async startRetakeCourse() {
      this.$axios({
        method: "get",
        url: "/startRetakeCourse",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.retakeStatus = data.retakeStatus;
          this.successMessage(msg);
        }
      });
    },
    async closeRetakeCourse() {
      this.$axios({
        method: "get",
        url: "/closeRetakeCourse",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.retakeStatus = data.retakeStatus;
          this.successMessage(msg);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addRetakeCourse();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
