<template>
  <div>
    <el-card style="height: 100%">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>数据图</el-breadcrumb-item>
      </el-breadcrumb>
      <div
        id="echart"
        style="width: 100%; height: 550px; margin-top: 20px"
      ></div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "StudentChart",
  data() {
    return {};
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    async initCharts() {
      const myChart = this.$echarts.init(document.getElementById("echart"));
      // 绘制图表
      let option1;
      let option2;
      let option3;
      await this.$axios({
        method: "get",
        url: "/queryStudentChart",
      }).then((res) => {
        const { msg, data } = res.data;
        this.successMessage(msg);
        option1 = data.studentChartX;
        option2 = data.studentChartY;
        option3 = data.studentChartZ;
      });
      const option = {
        title: {
          text: "分数对比柱状图", // 主标题
          left: "center",
          // 主标题文本样式设置
          textStyle: {
            fontWeight: "bold",
            fontSize: "20px",
          },
        },
        tooltip: {},
        legend: {
          show: true,
          bottom: "2%",
        },
        xAxis: {
          data: option1,
          name: "科目",
        },
        yAxis: {
          //设置轴线
          axisLine: {
            show: true,
          },
          name: "总分",
        },
        series: [
          {
            name: "自己",
            type: "bar",
            data: option2,
          },
          {
            name: "平均",
            type: "bar",
            data: option3,
          },
        ],
      };
      myChart.setOption(option);
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped></style>
