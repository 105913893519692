import axios from "axios";

const service = axios.create({
  baseURL: "https://education.xiugen.cn",
  timeout: 3 * 1000,
});
// http request拦截器 添加一个请求拦截器
service.interceptors.request.use(
  function (config) {
    let token = sessionStorage.getItem("token");
    if (token) {
      //将token放到请求头发送给服务器,将tokenkey放在请求头中
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    const { status } = error.response;
    if (status === 401) {
      router.push({ path: "/login" });
    }
    return Promise.reject(error);
  }
);
export default service;
