<template>
  <el-card class="box-card">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>必修课列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="searchRow">
      <el-col>
        <el-input
          placeholder="请输入班级名"
          @clear="loadRequiredCourseList"
          clearable
          v-model="grade"
          @keydown.enter.native="queryRequiredCourse"
          class="inputSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="queryRequiredCourse"
          ></el-button>
        </el-input>
        <el-button
          type="primary"
          @click="queryRequiredCourseExcelData"
          icon="el-icon-download"
          id="addbutton"
          >导出</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="requiredCourseList"
      class="studentTable"
      :row-key="getRowKeys"
      tooltip-effect="dark"
      max-height="439px"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column type="index" label="序号" align="center" width="48">
        <template slot-scope="scope">
          {{ scope.row.index }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" label="课程号" width="90">
      </el-table-column>
      <el-table-column align="center" prop="course" label="课程名" width="108">
      </el-table-column>
      <el-table-column align="center" prop="grade" label="上课班级" width="304">
      </el-table-column>
      <el-table-column align="center" prop="number" label="人数" width="50">
      </el-table-column>
      <el-table-column align="center" prop="credit" label="学分" width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="teacher"
        label="任课老师"
        width="80"
      >
      </el-table-column>
      <el-table-column align="center" prop="classroom" label="教室" width="100">
      </el-table-column>
      <el-table-column align="center" prop="zcFrom" label="起(周)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="zcTo" label="止(周)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="week" label="周次" width="50">
      </el-table-column>
      <el-table-column align="center" prop="start" label="上(节)" width="60">
      </el-table-column>
      <el-table-column align="center" prop="end" label="下(节)" width="60">
      </el-table-column>
      <el-table-column align="center" label="修改" width="50">
        <template slot-scope="scope">
          <el-button
            type="primary"
            v-if="scope.row.teacher != null"
            @click="showAlterRequiredCourseDia(scope.row)"
            plain
            icon="el-icon-edit"
            circle
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="删除" width="50">
        <template slot-scope="scope">
          <el-button
            type="danger"
            @click="showDeleteRequiredCoursetMassage(scope.row)"
            plain
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-sizes="[6, 8, 10, 12]"
      :page-size="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!--修改必修课-->
    <el-dialog
      title="修改必修课"
      :visible.sync="dialogCourseFormVisibleAlter"
      style="text-align: center; margin-top: -90px; height: 900px"
      width="750px"
    >
      <el-form :model="form" label-width="80px">
        <el-form-item label="课程号">
          <el-input
            v-model="form.id"
            autocomplete="off"
            disabled
            placeholder="请输入课程号"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程名">
          <el-input
            v-model="form.course"
            autocomplete="off"
            placeholder="请输入课程名"
          ></el-input>
        </el-form-item>
        <el-form-item label="学分">
          <el-input
            v-model="form.credit"
            autocomplete="off"
            placeholder="请输入学分"
          ></el-input>
        </el-form-item>
        <el-form-item label="老师">
          <el-select
            style="width: 100%"
            v-model="form.teacher"
            filterable
            placeholder="请选择老师"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.teacher"
              :value="item.teacher"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择开始日期"
              v-model="form.from"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="请选择结结束日期"
              v-model="form.to"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="星期">
          <el-select v-model="form.week" style="width: 100%">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课节次">
          <el-select v-model="form.start" style="width: 100%">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
            <el-option label="8" value="8"></el-option>
            <el-option label="9" value="9"></el-option>
            <el-option label="10" value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下课节次">
          <el-select v-model="form.end" style="width: 100%">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
            <el-option label="8" value="8"></el-option>
            <el-option label="9" value="9"></el-option>
            <el-option label="10" value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教室">
          <el-select
            style="width: 100%"
            v-model="form.classroom"
            filterable
            placeholder="请选择教室"
          >
            <el-option
              v-for="item in classroomList"
              :key="item.id"
              :label="item.classroom"
              :value="item.classroom"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -40px">
        <el-button @click="dialogCourseFormVisibleAlter = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="updateRequiredCourse"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { export_json_to_excel } from "@/excel/Export2Excel"; // 文件引入
export default {
  name: "RequiredCourse",
  data() {
    return {
      pagenum: 1,
      pagesize: 6,
      total: 0,
      requiredCourseList: [],
      teacherList: [],
      classroomList: [],
      grade: "",
      form: {
        id: "",
        course: "",
        teacher: "",
        week: "",
        start: "",
        end: "",
        classroom: "",
        from: "",
        to: "",
        credit: "",
      },
      dialogCourseFormVisibleAlter: false,
    };
  },
  created() {
    this.queryAllRequiredCourse();
  },
  methods: {
    exportExcel() {
      // 表头对应关系（因为数据中的key是英文，想要导出的表头是中文的话，需要将中文和英文做对应）
      const headers = {
        课程号: "id",
        课程名: "course",
        学分: "credit",
        上课班级: "grade",
        上课人数: "number",
        任课老师: "teacher",
        教室: "classroom",
        开始时间: "from",
        结束时间: "to",
        "起(周)": "zcFrom",
        "末(周)": "zcTo",
        星期: "week",
        上课节次: "start",
        下课节次: "end",
      };
      const data = this.formatJson(headers, this.excelData);
      export_json_to_excel({
        header: Object.keys(headers),
        data,
        filename: "必修课表",
        sheet: "必修课",
      });
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      //首先遍历数组
      return rows.map((item) => {
        // item是对象  => 转化成只有值的数组 => 数组值的顺序依赖headers  {username: '张三'  }
        // Object.keys(headers)  => ["姓名", "手机号",...]
        return Object.keys(headers).map((key) => {
          return item[headers[key]]; // 得到 ['张三'，’129‘，’dd‘,'dd']
        });
      });
    },
    async queryRequiredCourseExcelData() {
      this.$axios({
        method: "get",
        url: "/queryRequiredCourseExcelData",
      }).then((res) => {
        const { data } = res.data;
        this.excelData = data.excelData;
        this.exportExcel();
      });
    },
    async queryClassroomByNumber(number) {
      this.$axios({
        method: "get",
        params: {
          number: number,
        },
        url: "/queryClassroomByNumber",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.classroomList = data.classroomList;
        }
      });
    },
    async queryAllRequiredCourse() {
      this.$axios({
        method: "get",
        params: {
          grade: this.grade,
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryAllRequiredCourse",
      }).then((res) => {
        const { code, msg, data } = res.data;
        if (code === 200) {
          this.requiredCourseList = data.requiredCourseList;
          this.teacherList = data.teacherList;
          this.total = data.count;
          for (let i = 0; i < this.requiredCourseList.length; i++) {
            this.requiredCourseList[i].index = i + 1;
          }
        }
      });
    },
    async queryRequiredCourse() {
      if (this.grade === "") return;
      this.$axios({
        method: "get",
        params: {
          grade: this.grade,
          num: this.pagenum,
          size: this.pagesize,
        },
        url: "/queryRequiredCourse",
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          this.requiredCourseList = data.requiredCourseList;
          this.total = data.count;
          for (let i = 0; i < this.requiredCourseList.length; i++) {
            this.requiredCourseList[i].index = i + 1;
          }
        }
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      if (this.grade === "") this.queryAllRequiredCourse();
      else this.queryRequiredCourse();
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      if (this.grade === "") this.queryAllRequiredCourse();
      else this.queryRequiredCourse();
    },
    showAlterRequiredCourseDia(course) {
      this.form = JSON.parse(JSON.stringify(course)); //深拷贝
      this.queryClassroomByNumber(course.number);
      this.dialogCourseFormVisibleAlter = true;
    },
    async updateRequiredCourse() {
      this.$axios({
        method: "post",
        url: "/updateRequiredCourse",
        data: {
          id: this.form.id,
          course: this.form.course,
          teacher: this.form.teacher,
          week: Number(this.form.week),
          start: Number(this.form.start),
          end: Number(this.form.end),
          classroom: this.form.classroom,
          from: this.form.from,
          to: this.form.to,
          credit: Number(this.form.credit),
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          this.successMessage(msg);
          this.dialogCourseFormVisibleAlter = false;
          if (this.grade != "") this.queryRequiredCourse();
          else this.queryAllRequiredCourse();
        } else {
          this.errorMessage(msg);
        }
      });
    },
    getRowKeys(row) {
      return row.id + row.grade + row.teacher;
    },
    async loadRequiredCourseList() {
      await this.queryAllRequiredCourse();
    },
    //删除用户盒子
    showDeleteRequiredCoursetMassage(e) {
      this.$confirm("删除该班级?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.total === 1) this.pagenum = 1;
          else if ((this.pagenum - 1) * this.pagesize + 1 === this.total)
            this.pagenum--;
          if (e.children != null) this.deleteRequiredCourse(e);
          else this.deleteRequiredCourseChildren(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            center: true,
            duration: 1500,
          });
        });
    },
    async deleteRequiredCourse(e) {
      this.$axios({
        method: "get",
        url: "/deleteRequiredCourse",
        params: {
          course: e.id,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          if (this.grade != "") this.queryRequiredCourse();
          else this.queryAllRequiredCourse();
          this.successMessage(msg);
        }
      });
    },
    async deleteRequiredCourseChildren(e) {
      this.$axios({
        method: "get",
        url: "/deleteRequiredCourseChildren",
        params: {
          course: e.id,
          grade: e.grade,
        },
      }).then((res) => {
        const { code, msg } = res.data;
        if (code === 200) {
          if (this.grade != "") this.queryRequiredCourse();
          else this.queryAllRequiredCourse();
          this.successMessage(msg);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addRequiredCourse();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    successMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "success",
        center: true,
        duration: 1500,
      });
    },
    errorMessage(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: "error",
        center: true,
        duration: 1500,
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  height: 100%;
}
.studentTable {
  width: 100%;
  height: 439px;
  overflow: auto;
  margin-top: 30px;
}
.inputSearch {
  width: 300px;
}

.searchRow {
  margin-top: 30px;
}

#addbutton {
  margin-left: 20px;
}
.select1 {
  margin-left: -200px !important;
  text-align: left;
}
</style>
